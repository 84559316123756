import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-richiesta-reset-password',
  templateUrl: './richiesta-reset-password.component.html',
  styleUrls: ['./richiesta-reset-password.component.scss']
})
export class RichiestaResetPasswordComponent implements OnInit {

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  formReset!: FormGroup;

  constructor(private _api: ApiService, private _router: Router, private _token: TokenStorageService) { }

  ngOnInit(): void {

    // Rimuovo lo possibilità di scorrere la pagina
    document.getElementsByTagName('body')[0].classList.add('noscroll')

    // Creo il form con i controlli
    this.formReset = new FormGroup({
      email: new FormControl(null, Validators.required)
    })
  }

  enableScroll() {
    // Riabilito lo scroll
    document.getElementsByTagName('body')[0].classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._router.navigate(['/login']);
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
  }

  onSubmit(): void {

    let email = this.formReset.get('email')?.value;
    this.loading = true;

    // Passaggio email inserita all'api per l'invio dell'email
    this._api.richiestaReset(email).subscribe({
      error: (err) => {
        this.alertMessage = err;
        this.alertVisible = true;
        this.alertType = 'error';
        this.loading = false;
      },
      complete: () => {
        this.alertMessage = "Un'email è stata inviata all'indirizzo inserito";
        this.alertVisible = true;
        this.alertType = 'success';
        this.loading = false;
      }
    });
  }

}