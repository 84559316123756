import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private router: Router) { }

  setTarget(route: string) {
    sessionStorage.setItem('redirect', route);
  }
  getTarget() {
    return sessionStorage.getItem('redirect');
  }
  clearTarget() {
    sessionStorage.removeItem('redirect');
  }
  go() {    
    if (!this.getTarget()) {
      return;
    }
    this.router.navigate([this.getTarget()]);
    this.clearTarget();
  }
}
