import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, CarrelloNonPagati, Ordine } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-ordini-sospesi',
  templateUrl: './ordini-sospesi.component.html',
  styleUrls: ['../storico-ordini.component.scss', './ordini-sospesi.component.scss']
})
export class OrdiniSospesiComponent implements OnInit {

  constructor(private _api: ApiService, private _cart: CartService, private _token: TokenStorageService, private _router: Router, private _route: ActivatedRoute, private _notification: NzNotificationService) { }

  user?: AuthUser;

  ordini: Ordine[] = [];

  ricerca_ordini: string = '';

  checked: boolean = false;
  loading: boolean = false;
  mostraInfoBonifici: boolean = false;
  mostraInfoTesseramenti: boolean = false;

  idOrdineEsempio: string | number = '0000';

  ordini_selezionati = new Set<Ordine>();

  ngOnInit(): void {

    // Recupero i parametri passati nell'url
    this._route.queryParams.subscribe(params => {
      let pagamento = params['pagamento'] || '';

      // Se non ha pagato il tesseramento faccio apparire il modal
      if (pagamento.length > 0 || pagamento != undefined)
        this.mostraInfoTesseramenti = (pagamento == 'true')

      // Recupero l'utente
      this.user = this._token.getUser();

      // recupero gli ordini
      this._api.getOrdiniUtente(this.user!.id).subscribe({
        next: (ordini) => {
          
          // Recupero gli ordini non pagati e ciclo
          ordini.filter(ordine => ordine.stato.toLocaleLowerCase() == 'non pagato').map(ordine => {                  

            // Salvo l'ordine in una variabile
            const ordineRaggruppato = this.ordini.find((ord) => ord.id_cliente === ordine.id_cliente && ord.id_ordine == ordine.id_ordine);

            // Raggruppo gli ordini con lo stesso id 
            if (ordineRaggruppato) {
              ordineRaggruppato.prodotto += `, ${ordine.prodotto}`;

              if (ordineRaggruppato.cliente != ordine.cliente)
                ordineRaggruppato.cliente += `, ${ordine.cliente}`;

              ordineRaggruppato.prezzo += ordine.residuo;
            } else {
              this.ordini.push(ordine);
            }
          });
        },
        error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 })        
      })
    });
  }

  onItemChecked(ordine: Ordine, checked: boolean): void {
    if (checked)
      this.ordini_selezionati.add(ordine);
    else
      this.ordini_selezionati.delete(ordine);    
  }

  onPagamento() {

    // trasformo il set in array
    let ordini = Array.from(this.ordini_selezionati.values())

    // calcolo il totale degli ordini selezionati
    let totale = ordini.reduce((acc, ordine) => acc + ordine.prezzo, 0);

    let carrello_non_pagati: CarrelloNonPagati = { ordini: ordini, totale: totale };

    this._cart.subjectCarrelloNonPagati.next({ ...carrello_non_pagati });
    sessionStorage.setItem('carrello_non_pagati', JSON.stringify(carrello_non_pagati));

    this._router.navigate(['/cassa-non-pagati']);
  }

  onOrdinamentoOrdine(a: Ordine, b: Ordine) {
    return a.id_ordine.toString().localeCompare(b.id_ordine.toString())
  }

  onOrdinamentoProdotti(a: Ordine, b: Ordine) {
    return a.prodotto.localeCompare(b.prodotto)
  }

  onOrdinamentoMembro(a: Ordine, b: Ordine) {
    return a.cliente.localeCompare(b.cliente)
  }

  onOrdinamentoTotale(a: Ordine, b: Ordine) {
    return a.prezzo - b.prezzo;
  }

  onOrdinamentoDataAcquisto(a: Ordine, b: Ordine) {
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

}