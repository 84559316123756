import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router, private titleService: Title) { }

  loaded: boolean = false;

  @HostListener('window:load')
  onLoad() {
    document.getElementById('loader')?.classList.add('loaded');
    setTimeout(()=>{
      this.loaded = true;
    }, 1000)
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), map(() => {
      let route: ActivatedRoute = this.router.routerState.root;
      let routeTitle = '';
      while (route!.firstChild) {
        route = route.firstChild;
      }
      if (route.snapshot.data['title']) {
        routeTitle = route!.snapshot.data['title'];
      }
      return routeTitle;
    })).subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`Shop SciClub Bussoleno - ${title}`);
      }
    });
  }

}
