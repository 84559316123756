import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthUser, Prodotto, Tesseramento } from '../shared/interface.model';
import { CartService } from '../services/cart.service';
import { ApiService } from '../services/api.service';
import { TokenStorageService } from '../services/token-storage.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-skipass',
  templateUrl: './skipass.component.html',
  styleUrls: ['./skipass.component.scss']
})
export class SkipassComponent implements OnInit {

  @ViewChild('negozio') negozio!: ElementRef;

  prodotti: Prodotto[] = [];
  prodotti_vialattea: Prodotto[] = [];
  tesseramenti: Tesseramento[] = [];
  carrello?: { prodotti: Prodotto[], totale: number };

  filtroRapido: number | undefined;

  filterQuery: string = "";

  loading: boolean = true;
  soloAttivi: boolean = true;

  disable_all: boolean = true;
  disable_skipass: boolean = true;

  pagina: number = 1;

  user?: AuthUser;

  constructor(public _cart: CartService, private _api: ApiService, private _token: TokenStorageService, private _notification: NzNotificationService) { }

  ngOnInit(): void {

    // Abilito lo scroll al caricamento della pagina
    this.enableScroll();

    // Recupero i dati dell'utente salvati nel sessionStorage    
    this._token.subjectUser.subscribe((user) => {
      this.user = user == undefined ? this._token.getUser() : user;

      if (this.user) {
        // Recupero i dati in base all'età dell'utente
        this.getData(this.user.id, this.user.eta);
      } else {
        // Recupero i dati
        this.getData(0, 0);
      }
    })
  }

  getData(id: number, eta: number) {

    this.loading = true;

    // Recupero i prodotti in base all'età
    this._api.getProdotti(id, eta).subscribe({
      next: (data) => this.prodotti = [...data],
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {

        // Recupero i prodotti della vialatteao
        this.prodotti_vialattea = [...this.prodotti.filter(prod => (prod.id_categoria == 4 || prod.id_categoria == 13))]

        // Se l'utente ha effettuato il login procedo        
        if (this.user) {

          // Recupero i tesseramenti / ordini dell'utente
          this._api.getTesseramentiUtente(this.user.id).subscribe({
            next: (data) => this.tesseramenti = [...data],
            error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
            complete: () => {

              // Recupero le info e il carrello
              this.getInfo();
              this.getCarrello();

              this.loading = false;

            }
          })

        } else {

          this.tesseramenti = [];

          // Recupero le info e il carrello                    
          this.getInfo();
          this.getCarrello();

          this.loading = false;
        }
      }
    })
  }

  getInfo() {

    this.disable_all = true;

    // ciclo tra i prodotti
    this.prodotti.map(prodotto => {

      // Ciclo tra i tesseramenti del cliente
      this.tesseramenti.filter(tesseramento => tesseramento.id_prodotto == prodotto.id).map(tesseramento => {

        // Se il prodotto è già stato acquistato lo disabilito, tranne gli skipass
        if (prodotto.id_categoria != 13) {
          prodotto.disabilitato = true;
          prodotto.stato = '- (ACQUISTATO E VALIDO)'
          prodotto.attivo = true;
        }

        // Se il cliente ha già un prodotto obbligatorio disabilito il disable all          
        if (prodotto.obbligatorio) {
          this.disable_all = false;
        }

      })
    })
  }

  getCarrello() {

    // recupero il carrello
    this._cart.subjectCarrello.subscribe(cartData => {
      this.carrello = cartData;

      // controllo se il carrello ha prodotti
      if (cartData.prodotti.length > 0) {

        // ciclo per i prodotti
        this.prodotti.map(prodotto => {

          // Deseleziono tutti i prodotti
          prodotto.selezionato = false;

          // ciclo per i prodotti nel carrello
          this.carrello?.prodotti.filter(prodotto_cart => prodotto.id == prodotto_cart.id).map(prodotto_cart => {

            // Seleziono il prodotto che è dentro il carrello
            prodotto.selezionato = true;

            // se è presente un prodotto obbligatorio disattivo il disable all
            if (prodotto.obbligatorio)
              this.disable_all = false;

          })
        })
      } else {

        // Recupero i prodotti selezionati e ciclo
        this.prodotti.filter(prodotto => prodotto.selezionato).map(prodotto => {

          // Se il prodotto selezionato è obbligatorio disabilito il disable all
          if (prodotto.obbligatorio)
            this.disable_all = false;

          prodotto.selezionato = false;

        })
      }

      this.loading = false;
    })
  }

  enableScroll() {
    // Riabilito lo scroll
    document.getElementsByTagName('body')[0].classList.remove('noscroll')
  }

  toggleSoloAttivi() {
    this.soloAttivi = !this.soloAttivi;
  }

  onPageIndexChange(pageIndex: number, element: HTMLElement): void {
    this.pagina = pageIndex;
    element.scrollIntoView();
  }

  addCarrello(prodotto: Prodotto) {
    this._cart.addProduct(prodotto);
    prodotto.selezionato = true;
    // disattivo il disable all se il prodotto aggiunto è obbligatorio
    this.disable_all = prodotto.obbligatorio ? false : this.disable_all;
  }

  rimuoviCarrello(prodotto: Prodotto) {
    this._cart.removeProduct(prodotto.id);
    prodotto.selezionato = false;

    // se il prodotto è obbligatorio rimuovo tutto
    if (prodotto.obbligatorio) {
      this.disable_all = true;
      // Deseleziono tutti i prodotti
      this.prodotti.filter(prodotto => prodotto.selezionato).map(prodotto => prodotto.selezionato = false )
    }
  }

}