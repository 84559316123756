<div class="auth-wrapper login">
  <div class="form-container login border position-relative p-5">
    <button *ngIf="!pagamento" style="font-size: 100%; position: absolute; top: 5px; left: 15px; background: none;" type="button" class="btn-close" aria-label="Close" (click)="onGoBack()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>
    <button *ngIf="!pagamento" style="font-size: 80%; position: absolute; top: 15px; right: 15px" type="button" class="btn-close" aria-label="Close" (click)="onClose()" routerLink=".."></button>
    
    <div class="row d-flex mb-2 justify-content-center">
      <img class="logo" src="../../../assets/img/logo_short.png">
    </div>
    <div style="position: relative; height: 100%">
      <h2 class="text-center mb-4">Effettua il login</h2>      
      <nz-alert nzShowIcon *ngIf="error || errore_eta" nzType="error" [ngStyle]="{ 'margin-bottom': '16px' }" [nzMessage]="errore">
        <ng-template #errore>
          <p *ngIf="errore_eta" style="margin-bottom: 0px;">Utente minore di 18 anni. <br />Per cortesia contattare la segreteria dello Sci Club Bussoleno, in orario di apertura o scrivendo una mail a <a href="mailto:info@sciclubbussoleno.com">info&#64;sciclubbussoleno.com</a>,<br/> perché i MINORENNI NON POSSONO ACQUISTARE PRODOTTI DELLO SHOP SCI SCLUB BUSSOLENO</p>
          <p *ngIf="errore" style="margin-bottom: 0px;">{{error}}</p>
        </ng-template>
      </nz-alert>
      <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">
        <div class="input-container">
          <label for="email">* Email</label>
          <input nz-input type="email" name="email" formControlName="email" />
        </div>
        <div class="input-container">
          <label for="password">* Password</label>
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" autocomplete />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></span>
          </ng-template>
        </div>
        <a class="mb-3 float-end text-muted" style="font-size: 75%;" routerLink="/password" *ngIf="!pagamento">Password dimenticata?</a>
        <button class="bottone mt-2" nzType="primary" nz-button type="submit" [nzLoading]="loading" [disabled]="!formLogin.valid"> Accedi </button>
      </form>
      <div class="links" *ngIf="token_membro.length <= 0 && !pagamento">
        <nz-divider></nz-divider>
        <div class="text-center mt-4">
          <span style="font-size: 90%;">Non hai un account? <a class="fw-bold text-decoration-none text-black" routerLink="/registrazione">Registrati</a></span>
          <br />
          <span style="font-size: 90%;">Se sei un utente del vecchio portale, <a class="fw-bold text-decoration-none text-black" routerLink="/richiesta-password">clicca qui</a></span>
          <br>
          <span style="font-size: 90%;">ATTENZIONE, sei un minorenne? <a class="fw-bold text-decoration-none text-black" href="../../../assets/pdf/tutorial.pdf" target="_blank">clicca qui</a></span>
        </div>
      </div>

      <div class="links" *ngIf="pagamento">
        <nz-divider></nz-divider>
        <div class="text-center mt-4">
          <span style="font-size: 90%;">Vuoi tornare alla home page? <a class="fw-bold text-decoration-none text-black" routerLink="/">Clicca qui</a></span>          
        </div>
      </div>
      
    </div>
  </div>
</div>