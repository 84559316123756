import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Location } from '@angular/common';
import { Login, TokenGruppo } from 'src/app/shared/interface.model';
import { RedirectService } from 'src/app/shared/redirect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  constructor(private _redirect: RedirectService, private _api: ApiService, private _token: TokenStorageService, private _router: Router, private _route: ActivatedRoute, private _location: Location, private _notification: NzNotificationService) { }

  error: string = '';
  loading: boolean = false;
  passwordVisible: boolean = false;

  token: string = '';
  token_membro: string = '';

  url: string = '';
  sezione: string = '';

  data_token_membro!: TokenGruppo;
  data_login?: Login;

  errore_eta: boolean = false;
  pagamento: boolean = false;

  formLogin!: FormGroup;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formLogin = new FormGroup({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    })

    // Se c'è gia il token vado in home page
    if (this._token.getToken().length > 0)
      this._router.navigate(['']);


    // Rimuovo lo possibilità di scorrere la pagina
    document.getElementsByTagName('body')[0].classList.add('noscroll');

    // Recupero l'url della pagina
    this.url = this._router.url;

    // Recupero i paramentri passati nell'url
    this._route.queryParams.subscribe(params => {

      this.sezione = params['section'] || '';
      this.token_membro = this._token.getMembro();

      // Se è presente il token di un membro procedo
      if (this.token_membro.length > 0) {

        // Controllo che il token sia valido
        this._api.chekTokenGruppo(this.token_membro).subscribe({
          next: (data) => {
            // Se è valido salvo i dati del token in una variabile
            if (data.id_gruppo) {
              this.data_token_membro = data;
            } else {
              // Elimino il token se non è valido
              this._token.deleteMembro();
              this._notification.create('error', 'Si è verificato un errore', "Non è stato possibile accettare l'invito al gruppo, contattare l'assistenza oppure provare a riaprire il link arrivato tramite email!", { nzPlacement: 'bottomLeft', nzDuration: 5000 });
            }
          },
          error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 })
        })
      }

      if (this.url.indexOf('esito-pagamento') > -1) {
        this.pagamento = true;
      }
    });

  }

  enableScroll() {
    // Riabilito lo scroll
    document.getElementsByTagName('body')[0].classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
    this._redirect.clearTarget();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  onSubmit(): void {
    this.loading = true;
    this.error = '';

    let login = {
      email: this.formLogin.get('email')?.value,
      password: this.formLogin.get('password')?.value
    }

    // Mando le credenziali inserite vero l'api
    this._api.login(login.email, login.password).subscribe({
      next: (data) => this.data_login = data,
      error: async (err) => {
        this.error = err;
        this.loading = false;
      },
      complete: async () => {        

        // Se trovato un utente la sue età è minore di 18, non lo faccio accedere
        if (this.data_login!.data.eta < 18) {
          this.errore_eta = true;
          this.loading = false;
        } else {

          await this.delay(1000);

          // Salvo i dati dell'utente
          this._token.setToken(this.data_login!.token);
          this._token.setUser(this.data_login!.data);

          this.loading = false;

          // Abilito lo scroll
          this.enableScroll();

          // Se c'è in token di un membro procedo
          if (this.token_membro.length > 0) {

            let invito = {
              id_cliente_invitato: this.data_login!.data.id.toString(),
              id_cliente: this.data_token_membro.id_utente.toString(),
              id_gruppo: this.data_token_membro.id_gruppo.toString()
            }

            // Aggiungo l'invito al database
            this._api.addInvito(invito).subscribe({
              error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
              complete: () => {

                if (this.sezione == 'gruppi')
                  this._router.navigate(['/gruppi']);
                else
                  this._router.navigate(['/']);
              }
            })

          } else {            

            // Redirecto in base alla sezione passata
            if (this.sezione == 'gruppi')
              this._router.navigate(['/gruppi']);
            else if (this.sezione == 'cassa')
              this._router.navigate(['/cassa']);
            else if (this.url.indexOf('esito-pagamento') > -1)
              this._router.navigate(['/esito-pagamento'], { queryParamsHandling: 'merge' });
            else
              this._router.navigate(['/']);

          }
        }
      }
    })
  }
}