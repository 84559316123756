<div class="auth-wrapper">
    <div class="form-container border position-relative p-5">
        <button style="font-size: 100%; position: absolute; top: 5px; left: 15px; background: none;" type="button"
            class="btn-close" aria-label="Close" (click)="onGoBack()"><i style="font-size: 20px;"
                class="bi bi-arrow-left"></i></button>
        <button style="font-size: 80%; position: absolute; top: 15px; right: 15px" type="button" class="btn-close"
            aria-label="Close" (click)="enableScroll()" routerLink=".."></button>
        <div class="row d-flex mb-2 justify-content-center">
            <img class="logo" src="../../../assets/img/logo_short.png">
        </div>
        <h2>Impersona cliente</h2>
        <i class="text-muted word-break text-center w-100">Inserisci l'email del cliente che vuoi impersonare <br /> e
            poi inserisci le tue credenziali di accesso al gestionale!</i>
        <form [formGroup]="formImpersona" (submit)="onSubmit()">
            <nz-alert *ngIf="error || error_eta" nzType="error" [ngStyle]="{ 'margin-bottom': '16px' }" [nzDescription]="errore">
                <ng-template #errore>
                  <p *ngIf="error_eta" style="margin-bottom: 0px;">Utente minore di 18 anni! Non può accedere allo SHOP</p>
                  <p *ngIf="errore" style="margin-bottom: 0px;">{{error}}</p>
                </ng-template>
            </nz-alert>
            <div class="input-container">
                <label for="email">* Email</label>
                <input nz-input type="email" name="email" formControlName="email" />
            </div>

            <div class="input-container">
                <label for="username">* Username</label>
                <input nz-input type="text" name="username" formControlName="username" />
            </div>

            <div class="input-container">
                <label for="password">* Password</label>
                <nz-input-group [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" autocomplete />
                </nz-input-group>
                <ng-template #suffixTemplate>
                    <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                        (click)="passwordVisible = !passwordVisible"></span>
                </ng-template>
            </div>

            <div class="cta-container">
                <button nz-button type="submit" nzType="primary" class="bottone" [disabled]="!formImpersona.valid" [nzLoading]="loading">Impersona</button>
            </div>
        </form>
    </div>
</div>