<div class="profile-container container">
  <div class="row mb-3">
    <div class="col">
      <i class="mt-3 bi bi-arrow-left fs-3 cursor-pointer" (click)="onGoBack()"></i>
    </div>
  </div>
  
  <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
  
  <nz-tabset>
    <nz-tab nzTitle="Informazioni generali">
      <h5 class="mt-3 mb-3">Dati personali</h5>      
      <form (ngSubmit)="onSubmit()" [formGroup]="formUtente">
        <div class="row flex-row-reverse">
          <!-- immagine -->
          <div class="col-12 col-md-3 d-flex align-items-center text-center" style="flex-direction: column">
            <div class="border propic rounded-circle mx-auto d-flex justify-content-center align-items-end p-1" [ngStyle]="{'background-image':'url('+ propic +')'}">              
            </div>
            <a nz-popconfirm nzPopconfirmTitle="Sicuro di voler eliminare l'immagine?" class="small mt-2" style="font-size: 14px;" (nzOnConfirm)="deleteImmagine()" *ngIf="utente?.immagine_file">Elimina</a>
          </div>
          <div class="col">
            <!-- nome cognome -->
            <div class="row">
              <div class="col-md-6">
                <div class="input-container">
                  <label>* Nome</label>
                  <input nz-input formControlName="nome">
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-container">
                  <label>* Cognome</label>
                  <input nz-input formControlName="cognome">
                </div>
              </div>
            </div>
            <!-- data luogo -->
            <div class="row">
              <div class="col-md-6">
                <div class="input-container">
                  <label>* Data di nascita</label>
                  <nz-date-picker [nzDisabledDate]="disabledDate" nzFormat="dd-MM-yyyy" formControlName="data_nascita"></nz-date-picker>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-container">
                  <label>* Comune di nascita</label>
                  <input nz-input formControlName="comune_nascita">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-container">
              <label>* Provincia di nascita</label>
              <input nz-input formControlName="provincia_nascita">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>* Stato di nascita</label>
              <input nz-input formControlName="nazione_nascita">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-container">
              <label>* Indirizzo di residenza</label>
              <input nz-input formControlName="indirizzo">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>Comune di residenza <i>(opzionale)</i></label>
              <input nz-input formControlName="comune">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-container">
              <label>Provincia di residenza <i>(opzionale)</i></label>
              <input nz-input formControlName="provincia">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>Stato di residenza <i>(opzionale)</i></label>
              <input nz-input formControlName="nazione">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-container">
              <label>* Codice Fiscale</label>
              <input nz-input formControlName="codice_fiscale">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>Telefono <i>(opzionale)</i></label>
              <input nz-input formControlName="telefono">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="input-container">
              <label>* Email</label>
              <input nz-input type="email" formControlName="email">
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>Immagine <i>(opzionale)</i></label><br>
              <nz-upload [(nzFileList)]="fileList" [nzShowButton]="fileList.length <= 0" [nzBeforeUpload]="onImmagineUpload" [nzRemove]="onRemoveImmagineUpload" nzAccept="image/*">
                <button nzSize="large" nz-button type="button">
                  <span class="align-middle" nz-icon nzType="upload"></span>
                  Carica immagine profilo
                </button>
              </nz-upload>
              <br>
              <span class="text-muted small">Nota: l'immagine deve ritrarre il tuo volto. Caricare un'immagine di tipo
                diverso può invalidare il tuo tesseramento e gli acquisti che effettui. Caricando una foto ne garantisci
                la correttezza.</span>
            </div>
          </div>
        </div>

        <!-- Newsletter -->
        <div class="row">
          <h5 class="mb-2">Newsletter</h5>
          <div class="col-md-6">
            <div class="checkbox-container">              
              <label nz-checkbox formControlName="newsletter">Voglio ricevere aggiornamenti e offerte tramite email</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 cta-container">
            <button nz-button nzType="primary" class="w-100" type="submit" [nzLoading]="loading" [disabled]="!formUtente.valid">Salva modifiche</button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </form>
    </nz-tab>
    <nz-tab nzTitle="Password">
      <form [formGroup]="formPassword" (ngSubmit)="onPassword()">
        <!-- Modifica password -->
        <h5 class="my-3">Modifica la password</h5>
        <div class="row">                    
          <div class="col">
            <div class="input-container">
              <label>* Vecchia password</label>
              <nz-input-group [nzSuffix]="suffixOldTemplate">
                <input [type]="passwordOldVisible ? 'text' : 'password'" nz-input formControlName="old_password" autocomplete />
              </nz-input-group>
              <ng-template #suffixOldTemplate>
                <span nz-icon [nzType]="passwordOldVisible ? 'eye-invisible' : 'eye'" (click)="passwordOldVisible = !passwordOldVisible"></span>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row">                    
          <div class="col-md-6">
            <div class="input-container">              
              <nz-form-label nzFor="new_password" nzTooltipTitle="La password deve contenere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale"><span>* Nuova Password</span></nz-form-label>            
              <nz-input-group [nzSuffix]="suffixNewTemplate">
                <input [type]="passwordNewVisible ? 'text' : 'password'" nz-input formControlName="new_password" autocomplete />
              </nz-input-group>
              <ng-template #suffixNewTemplate>
                <span nz-icon [nzType]="passwordNewVisible ? 'eye-invisible' : 'eye'" (click)="passwordNewVisible = !passwordNewVisible"></span>
              </ng-template>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">              
              <nz-form-label nzFor="confirm_password" nzTooltipTitle="La password deve contenere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale"><span>* Conferma la nuova Password</span></nz-form-label>            
              <nz-input-group [nzSuffix]="suffixConfirmTemplate">
                <input [type]="passwordConfirmVisible ? 'text' : 'password'" nz-input formControlName="confirm_password" />
              </nz-input-group>
              <ng-template #suffixConfirmTemplate>
                <span nz-icon [nzType]="passwordConfirmVisible ? 'eye-invisible' : 'eye'" (click)="passwordConfirmVisible = !passwordConfirmVisible"></span>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 cta-container">
            <button nz-button nzType="primary" class="w-100" type="submit" [nzLoading]="loading" [disabled]="!formPassword.valid">Modifica password</button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </form>
    </nz-tab>
    <nz-tab nzTitle="Contatti">
      <h5 class="my-3">Aggiungi contatto</h5>
      <p>Qui puoi agggiungere i tuoi contatti di emergenza. Lo staff dello Sci Club può visualizzare i dati che inserisci e contattare i tuoi famigliari in caso di necessità.</p>
      <form [formGroup]="formContatti" (ngSubmit)="onAddContatto()">        
        <div class="row">                    
          <div class="col-md-6">
            <div class="input-container">
              <label>* Nome</label>              
              <input type="text" nz-input formControlName="nome" />              
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>* Cognome</label>              
              <input type="text" nz-input formControlName="cognome" />              
            </div>
          </div>
        </div>
        <div class="row">                    
          <div class="col-md-6">
            <div class="input-container">
              <label>Email <i>(opzionale)</i></label>              
              <input type="email" nz-input formControlName="email" />              
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>Telefono <i>(opzionale)</i></label>              
              <input type="tel" nz-input formControlName="telefono" />              
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-container">
              <label>Parentela <i>(opzionale)</i></label>              
              <input type="text" nz-input formControlName="parentela" />              
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 cta-container">
            <button nz-button nzType="primary" class="w-100" type="submit" [nzLoading]="loading" [disabled]="!formContatti.valid">Crea contatto</button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </form>
      <h5 class="my-3 mt-5 mb-3">Lista contatti</h5>
      <div class="row mb-3" *ngIf="contatti.length > 0">
        <div class="col mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un contatto..." [(ngModel)]="ricerca_contatti">
        </div>    
      </div>  
      <p *ngIf="contatti.length < 1" class="text-center my-5 text-muted">Non hai associato nessun contatto...</p>
      <div class="row mt-2">
        <div class="col">
          <nz-table nzBordered #contattiTable *ngIf="contatti.length > 0" [nzData]="contatti | searchContatti: ricerca_contatti" nzShowPagination [nzTotal]="(contatti | searchContatti: ricerca_contatti).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger class="table-contatti">
            <thead>
              <tr>
                <th></th>
                <th [nzSortFn]="onOrdinamentoContatto">Cognome e Nome</th>               
                <th [nzSortFn]="onOrdinamentoParentela">Parentela</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let contatto of contattiTable.data | searchContatti: ricerca_contatti">
                <tr>
                  <td [nzExpand]="expandSet.has(contatto.id)" (nzExpandChange)="onExpandChange(contatto.id, $event)" class="text-center" nzTooltipTitle="Clicca per modificare i dati del contatto!" nzTooltipPlacement="topLeft" nz-tooltip></td>
                  <td>{{ contatto.cognome }} {{ contatto.nome }}</td>                              
                  <td>{{ contatto.parentela }}</td>
                  <td class="text-center"><a style="color: #1890ff;" nz-popconfirm nzPopconfirmTitle="Sei sicuro di volere eliminare il contatto?" class="text-center" (nzOnConfirm)="deleteContatto(contatto.id)" nzTooltipTitle="Elimina il contatto!" nzTooltipPlacement="top" nz-tooltip><span class="fs-5" nz-icon nzType="delete" nzTheme="outline"></span></a></td>
                </tr>
                <tr [nzExpand]="expandSet.has(contatto.id)">                
                    <div class="row">                    
                      <div class="col-md-6">
                        <div class="input-container">
                          <label>* Nome</label>              
                          <input type="text" nz-input [(ngModel)]="contatto.nome" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-container">
                          <label>* Cognome</label>              
                          <input type="text" nz-input [(ngModel)]="contatto.cognome" />              
                        </div>
                      </div>
                    </div>
                    <div class="row">                    
                      <div class="col-md-6">
                        <div class="input-container">
                          <label>Email <i>(opzionale)</i></label>              
                          <input type="email" nz-input [(ngModel)]="contatto.email" />              
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-container">
                          <label>Telefono <i>(opzionale)</i></label>              
                          <input type="tel" nz-input [(ngModel)]="contatto.telefono" />              
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="input-container">
                          <label>Parentela <i>(opzionale)</i></label>              
                          <input type="text" nz-input [(ngModel)]="contatto.parentela" />              
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3"></div>
                      <div class="col-md-6 cta-container">
                        <button nz-button nzType="primary" class="w-100" type="button" (click)="onUpdateContatto(contatto)" [nzLoading]="loading" [disabled]="contatto.nome.length <= 0 || contatto.cognome.length <= 0" >Modifica contatto</button>
                      </div>
                      <div class="col md-3"></div>
                    </div>                
                </tr>
              </ng-container>
            </tbody>
          </nz-table>
        </div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Buoni sconto">            
      <h5 class="my-3 mt-5 mb-3">Lista Buoni Sconto</h5>
      <p>Qui puoi trovare i buoni sconto legati al tuo account, con i relativi valori</p>
      <div class="row mb-3" *ngIf="codici_sconto.length > 0">
        <div class="col mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un codice..." [(ngModel)]="ricerca_codice">
        </div>    
      </div>  
      <p *ngIf="codici_sconto.length < 1" class="text-center my-5 text-muted">Non hai associato nessun buono sconto...</p>
      <div class="row mt-2">
        <div class="col">
          <nz-table nzBordered #codiciTable *ngIf="codici_sconto.length > 0" [nzData]="codici_sconto | searchCodiceSconto: ricerca_codice" nzShowPagination [nzTotal]="(codici_sconto | searchCodiceSconto: ricerca_codice).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger class="table-codici">
            <thead>
              <tr>
                <th [nzSortFn]="onOrdinamentoCodice">Codice</th>
                <th [nzSortFn]="onOrdinamentoValore">Valore</th>                               
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let codice_sconto of codiciTable.data | searchCodiceSconto: ricerca_codice">
                <tr>                  
                  <td>{{ codice_sconto.codice }}</td>                              
                  <td>{{ codice_sconto.valore | currency: 'EUR' }}</td>                  
                </tr>                
              </ng-container>
            </tbody>
          </nz-table>
        </div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Altro">
      <h5 class="my-3">Elimina il tuo profilo</h5>
      <div class="row mt-2">
        <div class="col">
          <p>Cancellando il tuo profilo cancellerai tutti i dati a te legati. Questi includono i prodotti a te
            intestati, i tuoi gruppi, i tuoi contatti, ma non la cronologia dei tuoi acquisti. <span class="fw-bold text-danger">Questa azione non è
            reversibile.</span></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 text-center mt-3 cta-container">
          <button nzSize="large" nz-button nzDanger nzType="primary" type="button" class="w-100" [nzLoading]="loading" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler eliminare l'utente? Questa azione è irreversibile." (nzOnConfirm)="deleteUtente()"> Elimina il mio profilo</button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </nz-tab>
  </nz-tabset>
</div>