<div class="container py-5">
    <h2>Termini e condizioni d'uso</h2>
    <p class="mt-3 fs-5" style="font-weight: 600;"> Condizioni di utilizzo dei servizi del sito shop.sciclubbussolenovalsusa.com</p>
    <p>
        Lo Sci Club Bussoleno A.S.D., di seguito denominato “Sci Club”, è proprietario del sito shop.sciclubbussolenovalsusa.com.
        Lo Sci Club non è in alcun modo responsabile per danni conseguenti all’uso improprio di dati ed informazioni contenute in tale sito web o desumibili da esso, nonché per danni conseguenti all’eventuale cessazione o modifica di quei servizi di propria titolarità per i quali non sia stata preventivamente stabilita le necessità di qualsiasi tipo di preavviso.
        Tutti i marchi registrati citati all’interno di questo sito sono proprietà dei rispettivi titolari.
        Tutto il materiale pubblicato all’interno del sito, laddove non altrimenti specificato, è da intendersi protetto da copyright.
    </p>
    <p class="fs-5" style="font-weight: 600;">Copyright</p>
    <p>
        Tutto il materiale pubblicato all’interno di shop.sciclubbussolenovalsusa.com, laddove non diversamente specificato, è da intendersi protetto da copyright.
        Pertanto, è vietata la riproduzione totale e/o parziale e/o la modifica di immagini, testi, loghi ed icone senza la specifica autorizzazione scritta dello Sci Club proprietario del sito.
        Lo Sci Club non è in alcun modo responsabile per eventuali link esterni, contenuti nel sito, che possano condurre l’utente a siti ed applicazioni non controllabili, nel contenuto e nel funzionamento, da parte dello stesso.
        Tali link sono proposti agli utenti ad esclusivo titolo informativo.
    </p>
</div>