<ng-template #indicatorTemplate>
    <img src="../../assets/img/riding-skiing.gif" alt="" width="250px">
  </ng-template>
  <nz-spin [nzSpinning]="caricamento" [nzIndicator]="indicatorTemplate"  class="spinning spinner" >
    <div class="container border p-0">      
      <div class="row p-lg-4 p-1 py-3 m-0 border-top">
        <div class="col">
          <h4>Cassa</h4>
          <p class="pb-0 mb-0">Attiva i prodotti selezionati! Seleziona il metodo e procedi al pagamento!</p>
        </div>
      </div>
      <div class="row border-top border-bottom m-0 p-0">                                                
        <div class="p-3 col-lg-4 border-end">          
          <div class="form-container p-3">                  
            <h4 class="mb-4 text-start">Metodo di pagamento</h4>
            <form [formGroup]="formMetodo">                                          
              <nz-radio-group formControlName="metodo">
                <label nz-radio class="mt-0 mb-2" *ngIf="metodo.carta" nzValue="Carta di credito">Carta di credito</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.carta" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Carta di Credito' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Carta di credito">Carta di credito</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="metodo.satispay" nzValue="Satispay">Satispay</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.satispay" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Satispay' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Satispay">Satispay</label>                  
              </nz-radio-group>                                                               
            </form>
          </div>
        </div>
        <div class="p-3 col-lg-8">          
          <div class="form-container p-3">                                                               
            <h4 class="mb-4 text-start">Riepilogo</h4>            

            <ul class="list-group list-group rounded-0">
              <li *ngFor="let ordine of carrello.ordini;">
                <ul class="list-group list-group rounded-0">
                  <li class="list-group-item fw-bold text-white bg-primary">Ordine #{{ordine.id_ordine}}</li>
                  <li class="list-group-item">{{ordine.prodotto}} | {{ordine.prezzo | currency: 'EUR'}}
                    <span class="ms-2 fs-5 text-muted float-end delete cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di volere rimuovere l'ordine dal pagamento?" nz-icon nzType="delete" nzTheme="outline" (nzOnConfirm)="onRimuoviProdotto(ordine.id)"></span>
                  </li>
                                       
                </ul>
              </li>              
              <li class="list-group-item"><strong>Totale</strong>: {{carrello.totale| currency: 'EUR'}}</li>
            </ul> 
          </div>
        </div>                                   
      </div>
      <div class="row p-3">
        <div class="col d-flex align-items-center justify-content-end checkout-desktop">
          <!-- <h6 class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{carrello.totale| currency: 'EUR'}}</h6>-->
          <button class="d-inline btn rounded-0 border me-3" type="button" (click)="onGoBack()">Indietro</button>
          <button class="d-inline btn btn-primary rounded-0 me-3" type="button" [disabled]="!formMetodo.valid" nz-tooltip [nzTooltipTitle]="'Selezionare un metodo di pagamento per procedere con l\'ordine'" (click)="onPagamento()">Vai alla cassa</button>          
        </div>
        <div class="col checkout-mobile">
          <!-- <h6 class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{carrello.totale| currency: 'EUR'}}</h6>-->
          <button class="d-inline btn rounded-0 border me-3 col bottone mt-3 mb-0" type="button" (click)="onGoBack()">Indietro</button>
          <button class="d-inline btn btn-primary rounded-0 col bottone" type="button" [disabled]="!formMetodo.valid" nz-tooltip [nzTooltipTitle]="'Selezionare un metodo di pagamento per procedere con l\'ordine'" (click)="onPagamento()">Vai alla cassa</button>                    
        </div>
      </div>
    </div>
  </nz-spin>