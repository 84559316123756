import { Pipe, PipeTransform } from '@angular/core';
import { Prodotto } from './interface.model';

@Pipe({
  name: 'onlyActive',
  pure: false
})
export class OnlyActivePipe implements PipeTransform {

  transform(prodotti: Prodotto[], active: boolean): any {
    if (active) {
      return prodotti.filter((prodotto: Prodotto) => !prodotto.disabilitato)
    }
    return prodotti;
  }

}
