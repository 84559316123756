<div class="container">
    <h2>Assicurazione</h2>

    <h4>Sulle piste da sci essere Assicurati è Obbligatorio!!</h4>

    <p>Come ormai ben saprete a partire da questa stagione invernale, sarà obbligatorio per gli sciatori essere in possesso di una Assicurazione RC valida.</p>
    <p>Lo sci club bussoleno propone ai propri soci la possibilità di sottoscrivere una polizza assicurativa con copertura sia in caso di infortunio che per Responsabilità Civile (danni a cose e/o persone)</p>
    <p>Potrete scegliere la polizza a voi più congeniale tre le 3 nostre proposte</p>
    <p>In caso di controlli in pista da parte delle forze dell’ordine, <strong>consigliamo al socio che sottoscrive anche l’assicurazione di portare sempre con sè copia cartacea o digitale dell copertura assicurativa sottoscritta, associata alla ricevuta di pagamento effettuata allo sci club.</strong></p>
    <p>In caso di infortunio, l’associato dovrà compilare apposito modulo di denuncia e inviarlo entro 5 gg dalla data dell’infortunio come previsto dalla polizza:</p>

    <div class="row mt-5">
        <div class="col-md-4 text-center">
            <a href="../../../assets/pdf/assicurazione_neve_base.pdf" target="_blank">
                <img src="../../../assets/img/assicurazione_neve_base.jpeg" alt="Assicurazione neve base" class="w-100">
            </a>

            <p class="mt-3"><strong>Prezzo: 11,50€</strong></p>

        </div>
        <div class="col-md-4 text-center">
            <a href="../../../assets/pdf/assicurazione_global_neve.pdf" target="_blank">
                <img src="../../../assets/img/assicurazione_global_neve.jpeg" alt="Assicurazione Global Neve" class="w-100">
            </a>

            <p class="mt-3"><strong>Prezzo: 15,00€</strong></p>
        </div>
        <div class="col-md-4 text-center">
            <a href="../../../assets/pdf/assicurazione_global_sport.pdf" target="_blank">
                <img src="../../../assets/img/assicurazione_global_sport.jpeg" alt="Assicurazione Global Sport" class="w-100">
            </a>

            <p class="mt-3"><strong>Prezzo: 35,00€</strong></p>
        </div>
    </div>
</div>
