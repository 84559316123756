import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenGruppo } from '../../shared/interface.model';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-membro',
  templateUrl: './membro.component.html',
  styleUrls: ['./membro.component.scss']
})
export class MembroComponent implements OnInit {

  constructor(private _api: ApiService, private _router: Router, private _route: ActivatedRoute, private _token: TokenStorageService, private _location: Location) { }

  token!: string;
  dati?: TokenGruppo;

  ngOnInit(): void {

    // Rimuovo lo possibilità di scorrere la pagina
    document.getElementsByTagName('body')[0].classList.add('noscroll')

    // Recupero i parametri passati nell'url
    this._route.queryParams.subscribe(params => {

      this.token = params['token'];

      // Se il token è stato passato procedo
      if (this.token) {

        // Controllo se il token è valido
        this._api.chekTokenGruppo(this.token).subscribe({
          next: (data) => {
            if (!data.id_gruppo) {
              this._router.navigate(['/']);
            } else {
              // Salvo i dati del gruppo
              this.dati = data;
              this._token.setMembro(this.token);
            }
          },
          error: (err) => this._router.navigate(['/'])
        })
      } else {
        this._router.navigate(['/']);
      }
    })
  }

  enableScroll() {
    // Riabilito lo scroll
    document.getElementsByTagName('body')[0].classList.remove('noscroll')
  }

  onClose() {
    this.enableScroll();
    this._token.deleteMembro();
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

}