import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, CarrelloGruppo, CarrelloMembro, Gruppo, Membri, Prodotto, Tesseramento, Utente, Categorie, Ordine } from 'src/app/shared/interface.model'

@Component({
  selector: 'app-acquisto-gruppo',
  templateUrl: './acquisto-gruppo.component.html',
  styleUrls: ['./acquisto-gruppo.component.scss']
})
export class AcquistoGruppoComponent implements OnInit {

  constructor(private _api: ApiService, private _token: TokenStorageService, private _cart: CartService, private _route: ActivatedRoute, private _router: Router, private _notification: NzNotificationService) { }

  public disabledDate = (current: Date): boolean => current > new Date();

  caricamento: boolean = true;
  disable_all: boolean = true;
  disable_skipass: boolean = true;
  soloAttivi: boolean = true;

  user!: AuthUser;
  utente?: Utente;

  gruppo?: Gruppo;
  membri: Membri[] = [];
  membroSelezionato?: Membri = undefined;
  categorie: Categorie[] = [];
  cat: any;

  prodotto?: Prodotto;
  prodotti: Prodotto[] = [];
  tesseramenti: Tesseramento[] = [];
  ordini: Ordine[] = [];
  totale: number = 0;

  carrello: CarrelloGruppo = { gruppo: 0, carrello: [] };
  contaProdotti: any = {};

  img_profilo: string = '';
  ricerca: string = '';

  infoUtente!: FormGroup;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.infoUtente = new FormGroup({
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      codice_fiscale: new FormControl(null)
    })

    // Recupero le categorie
    this._api.getCategorie().subscribe({
      next: (data) => this.categorie = [...data],
      error: (err) => this._router.navigate(['/gruppi']),
      complete: () => this.getDataUser()
    })
  }

  getDataUser() {

    // Recupero l'utente loggato
    this.user = this._token.getUser();
    this.infoUtente.disable();

    // Recupero i parametri passati nell'url
    this._route.params.subscribe(params => {

      let id_gruppo = params['id'];
      this.carrello.gruppo = id_gruppo;

      // Recupero dati gruppo
      this._api.getGruppo(id_gruppo).subscribe({
        next: (data) => this.gruppo = data,
        error: (err) => this._router.navigate(['/gruppi']),
        complete: () => {

          // Se il cliente loggato non è il capogruppo torno indietro
          if (this.gruppo!.id_cliente != this.user!.id)
            this._router.navigate(['/gruppi']);

          // Recupero i membri del gruppo
          this._api.getMembriGruppo(id_gruppo).subscribe({
            next: (data) => this.membri = [...data],
            error: (err) => this._router.navigate(['/gruppi']),
            complete: () => {
              this.caricamento = false;
              this.getCarrello();
            }
          })
        }
      })
    })
  }

  toggleSoloAttivi() {
    this.soloAttivi = !this.soloAttivi;
  }

  setProdottoInfo(prodotto: Prodotto) {
    this.prodotto = prodotto;
  }

  selezMembro(membro: Membri | undefined) {

    // se il membro era già selezionato non procedo
    if (membro === this.membroSelezionato || !membro)
      return;

    this.caricamento = true;

    this.totale = 0;
    this.disable_all = true;
    // this.disable_skipass = true;

    // creo il record nel carrello per il membro selezionato se non era presente
    const alreadyExists = this.carrello.carrello.find((carrello) => membro.id === carrello.cliente.id);
    if (!alreadyExists) { this.carrello.carrello.push({ cliente: membro, prodotti: [] }) }

    this.membroSelezionato = membro;

    // recupero i dati del membro (immagine e data di nascita)
    this._api.getUtente(membro.id).subscribe({
      next: (data) => this.utente = data,
      error: (err) => this._router.navigate(['/gruppi']),
      complete: () => {

        // Salvo i dati dell'utente recuperato e li aggiungo nel form
        this.infoUtente.patchValue(this.utente!);
        this.img_profilo = this.utente!.immagine_file.length > 0 ? this.utente!.immagine_file : '../../assets/img/avatar.jpg';

        // calcolo l'età del membro
        let differenza = Math.abs(Date.now() - new Date(this.utente!.data_nascita).getTime());
        let eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);

        // recupero i prodotti per la sua età
        this._api.getProdotti(this.utente!.id, eta).subscribe({
          next: (data) => this.prodotti = [...data],
          error: (err) => this._router.navigate(['/gruppi']),
          complete: () => {

            // recupero i suoi tesseramenti attivi
            this._api.getTesseramentiUtente(this.utente!.id).subscribe({
              next: (data) => this.tesseramenti = [...data],
              error: (err) => this._router.navigate(['/gruppi']),
              complete: () => {

                // recupero gli ordini del membro selezionato
                this._api.getOrdiniUtente(this.utente!.id).subscribe({
                  next: (data) => this.ordini = [...data],
                  error: (err) => this._router.navigate(['/gruppi']),
                  complete: () => {

                    setTimeout(() => {

                      // controllo che i prodotti siano disponibile e recupero il carrello
                      this.getInfo();
                      this.getCarrello();

                      // ordino i prodotti mostrando prima quelli abilitati
                      this.prodotti.sort(function (a, b) { return (b.disabilitato === a.disabilitato) ? 0 : b.disabilitato ? -1 : 1; });

                      this.caricamento = false;

                    }, 500);

                  }
                })
              }
            })
          }
        })
      }
    })
  }

  getInfo() {

    this.disable_all = this.checkTesseramento();

    // Ciclo tra i tesseramenti del cliente
    this.tesseramenti.map(tesseramento => {

      // ciclo tra i prodotti
      this.prodotti.filter(prodotto => tesseramento.id_prodotto == prodotto.id).map(prodotto => {

        // Se il prodotto è già stato acquistato lo disabilito, tranne gli skipass
        if (prodotto.id_categoria != 13) {
          prodotto.disabilitato = true;
          prodotto.stato = '- (ACQUISTATO E VALIDO)'
          prodotto.attivo = true;
        }

      })
    })

    // Disabilito i prodotti tessera + assicurazione per l'utente LUCA SORO
    this.prodotti.map(prodotto => {
      if (prodotto.tesseramento && prodotto.assicurativo && this.membroSelezionato!.id == 8141) {
        prodotto.disabilitato = true;
        prodotto.stato = '- (ACQUISTATO BLOCCATO)';
      }
    })

    // Se c'è il tessermanto aggiungo il testo 'rinnovo'                          
    const ordine_tesseramento = this.ordini.find(ordine => ordine.id_prodotto == 3 && ordine.id_cliente == this.membroSelezionato!.id);
    if (ordine_tesseramento) {
      this.prodotti.filter(prodotto => prodotto.id == 3).map(prodotto => prodotto.nome = 'Rinnovo ' + prodotto.nome)
    }
  }

  checkTesseramento() {

    let check = true;

    // Ciclo tra i tesseramenti del cliente
    this.tesseramenti.map(tesseramento => {

      // ciclo tra i prodotti
      this.prodotti.filter(prodotto => tesseramento.id_prodotto == prodotto.id).map(prodotto => {

        // Se il cliente ha già un prodotto obbligatorio disabilito il disable_all
        if (prodotto.obbligatorio)
          check = false;
      });
    });

    return check;
  }

  getCarrello() {

    // recupero carrello
    this._cart.subjectCarrelloGruppo.subscribe(cartData => {

      // controllo se il carrello è presente
      if (cartData) {

        // controllo se il carrello ha più di un prodotto
        if (cartData.carrello.length > 0) {

          // se il carrello è di questo gruppo lo mostro      
          if (this.gruppo?.id == cartData.gruppo) {

            // se presente lo recupero
            this.carrello = cartData;

            // recupero il totale dei prodotti   
            this.totale = this.carrello.carrello.reduce((somma_cliente, cart) => { return somma_cliente + cart.prodotti.reduce((somma, prod) => somma + (prod.prezzo + (prod.supplemento || 0)), 0); }, 0);

            // ciclo nel carello per ogni membro
            this.carrello.carrello.map(cart => {

              // calcolo quanti prodotti ha il cliente selezionato
              this.contaProdotti[cart.cliente.id] = cart.prodotti.length;

              // ciclo nei prodotti dei membri nel carrello
              cart.prodotti.filter(prodotto_cart => this.membroSelezionato?.id == cart.cliente.id).map(prodotto_cart => {

                // ciclo tra i prodotti
                this.prodotti.filter(prodotto => prodotto.id == prodotto_cart.id).map(prodotto => {

                  // seleziono i prodotti che sono presenti nel carrello                    
                  prodotto.selezionato = true;

                  // se è presente un prodotto obbligatorio nel carrello abilito tutto in caso siano disabilitai
                  if (this.checkTesseramento() && prodotto.obbligatorio)
                    this.disable_all = false;

                })

              })
            })
          } else {

            this.carrello.gruppo = this.gruppo!.id;
            this.carrello.carrello = [];
            this._cart.subjectCarrelloGruppo.next(this.carrello);
            this.contaProdotti[this.membroSelezionato!.id] = 0;

          }
        }
      }
    })
  }

  onGoBack() {
    this._router.navigate(['/gruppi'])
  }

  aggiungiProdotto(prodotto: Prodotto) {

    // se il prodotto è selezionato lo tolgo dal carrello
    if (!prodotto.selezionato) {

      prodotto.selezionato = true;

      // se il prodotto NON è selezionato lo inserisco nel carrello, ciclo per il carrello
      this.carrello.carrello.filter(cart => this.membroSelezionato?.id == cart.cliente.id).map(cart => {

        // se il prodotto era obbligatorio abilito tutti gli altri prodotti
        if (this.checkTesseramento() && prodotto.obbligatorio)
          this.disable_all = false;

        cart.prodotti.push(prodotto);
        this._cart.subjectCarrelloGruppo.next(this.carrello);
        this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
        //this._notification.create('success', 'Prodotto aggiunto al carrello', `${prodotto.nome} è stato aggiunto con successo al carrello`, { nzPlacement: 'bottomLeft', nzDuration: 1000 });
        return;
      })


    } else {

      prodotto.selezionato = false;

      // ciclo per il carrello del gruppo
      this.carrello.carrello.filter(cart => this.membroSelezionato?.id == cart.cliente.id).map(cart => {

        // se il prodotto è obbligatorio, quando viene rimosso tolgo tutto
        if (this.checkTesseramento() && prodotto.obbligatorio) {

          this.disable_all = cart.prodotti.filter(prod => prod.id !== prodotto.id && prod.obbligatorio).length <= 0;
          cart.prodotti = cart.prodotti.filter(prod => prod.id !== prodotto.id && prod.obbligatorio).length > 0 ? cart.prodotti.filter(prod => prod.id !== prodotto.id) : [];
          this._cart.subjectCarrelloGruppo.next(this.carrello);

          // se non ci sono altri prodotti obbligatori recupero gli altri prodotti selezionati e li deseleziono
          if (cart.prodotti.filter(prodotto => prodotto.obbligatorio).length <= 0)
            this.prodotti.filter(prodotto => prodotto.selezionato && !prodotto.obbligatorio).map(prodotto => prodotto.selezionato = false)

          this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
          //this._notification.create('success', 'Prodotti rimossi dal carrello', `È stato rimosso il tesseramento dello SciClub dal carrello, tutti gli altri prodotti sono stati rimossi!`, { nzPlacement: 'bottomLeft', nzDuration: 3000 });
          return;
        } else {
          let updatedProducts = cart.prodotti.filter((prod: Prodotto) => prod.id !== prodotto.id);
          cart.prodotti = updatedProducts;
          this._cart.subjectCarrelloGruppo.next(this.carrello);
          this.contaProdotti[cart.cliente.id] = cart.prodotti.length;
          //this._notification.create('success', 'Prodotto rimosso dal carrello', `${prodotto.nome} è stato rimosso con successo dal carrello`, { nzPlacement: 'bottomLeft', nzDuration: 1000 });
          return;
        }

      })
    }

    // calcolo il totale del carrello per tutti i membri    
    this.totale = this.carrello.carrello.reduce((somma_cliente, cart) => { return somma_cliente + cart.prodotti.reduce((somma, prod) => somma + (prod.prezzo + (prod.supplemento || 0)), 0); }, 0);
  }

  cassa() {

    let updatedCart = this.carrello.carrello.filter((cart: CarrelloMembro) => cart.prodotti.length > 0);
    this.carrello.carrello = updatedCart;

    this._cart.subjectCarrelloGruppo.next(this.carrello);
    this._router.navigate(['/gruppi/' + this.gruppo!.id + '/cassa'])
  }
}