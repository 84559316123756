<div class="container">
    <h2>Ski Card SkipassOpen</h2>

    <p>La  Card SKIPASSOPEN permette di ottenere ottimi sconti sull’acquisto dello <strong>SKIPASS ONLINE</strong> su molte stazioni sciistiche italiane e francesi come ad esempio: <strong>MONGINEVRO</strong>, Les 2 Alpes, <strong>Orelle ValThorens</strong>, Valloire, <strong>SERRE CHEVALIER</strong>, La Thuile+La Rosiere, <strong>BARDONECCHIA</strong>, Valtournenche, <strong>Cervinia-Valtournanche</strong>, Cervinia Zermatt, Courmayeur, <strong>MonterosaSki</strong>, MonterosaSki Freeride (con Indren),<strong>Pila</strong>, e tante altre</p>

    <h4>Rinnova a 5€</h4>
    <p>Il costo della card nuova è 10€. Potrai ricaricarla online a prezzo scontato Sci Club Bussoleno prima di recarti agli impianti.</p>

    <h4>Valida 1 anno</h4>
    <p>Potrai rinnovarla di stagione in stagione direttamente tramite il nostro sito quando rinnovi il tuo tesseramento allo sci club (2/3 giorni lavorativi per l’attivazione).</p>

    <h4>Ritiro</h4>
    <p>La Ski Card SkiPassOpen potrà essere ritirata, dopo una conferma avvenuata via email da parte dello Sci Club Bussoleno</p>

    <h4>ATTENZIONE!</h4>
    <p>La skicard dovrà essere sempre accompagnata dal documento di tesseramento allo Sci Club Bussoleno in corso di validità.</p>
</div>