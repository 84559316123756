<div class="auth-wrapper">
  <div class="form-container border position-relative p-5">
    <button style="font-size: 100%; position: absolute; top: 5px; left: 15px; background: none;" type="button" class="btn-close" aria-label="Close" (click)="onGoBack()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>        
    <button style="font-size: 80%; position: absolute; top: 15px; right: 15px" type="button" class="btn-close" aria-label="Close" (click)="onClose()" routerLink=".."></button>
    <div class="row d-flex mb-2 justify-content-center">
      <img class="logo" src="../../../assets/img/logo_short.png">
    </div>
    <h2>Recupera la tua password</h2>
    <i class="text-muted word-break text-center w-100">Inserisci l'indirizzo email a cui spedire la richiesta di cambio password!</i>    
    <form [formGroup]="formReset" (ngSubmit)="onSubmit()">
      <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
      <div class="input-container">
        <label for="email">* Email</label>
        <input nz-input type="email" name="email" placeholder="Email" formControlName="email" />
      </div>
      <div class="cta-container">
        <button nz-button type="submit" nzType="primary" class="bottone" [disabled]="!formReset.valid" [nzLoading]="loading">Recupera la tua password</button>
        <nz-divider></nz-divider>
        <div class="text-center mt-4">
          <span style="font-size: 90%;">Hai già un account? <a class="fw-bold text-decoration-none text-black" routerLink="/login">Effettua il login</a></span>
          <br />
          <span style="font-size: 90%;">Non hai un account? <a class="fw-bold text-decoration-none text-black" routerLink="/registrazione">Registrati</a></span>
          <br />
        </div>
      </div>
    </form>
  </div>
</div>