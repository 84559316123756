import { Pipe, PipeTransform } from '@angular/core';
import { Prodotto } from './interface.model';

@Pipe({ 
    name: 'pagination',
    pure: false 
})

export class PaginazionePipe implements PipeTransform {

    transform(prodotti: Prodotto[], page: number): any {        
        return [...prodotti.slice(9 * (page - 1), 9 * (page))]
    }
}