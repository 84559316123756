<div class="auth-wrapper">  
  <div class="form-container border position-relative p-5">              
    <button style="font-size: 80%; position: absolute; top: 15px; right: 15px" type="button" class="btn-close" aria-label="Close" (click)="onClose()" routerLink=".."></button>
    <div class="row d-flex mb-2 justify-content-center">
      <img class="logo" src="../../../assets/img/logo_short.png">
    </div>
    <h2>Conferma membro</h2>      

    <nz-divider></nz-divider>    

    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            Sei {{dati?.cognome}} {{dati?.nome}}?
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="mb-4">Hai ricevuto questo invito da parte di <strong>{{dati?.utente}}</strong> per entrare a far parte del gruppo <strong>{{dati?.nome_gruppo}}</strong> nello Shop dello SciClub Bussoleno. Per accedere a sconti e promozioni per SKI PASS e giornate di sport e divertimento sulla neve a prezzi scontatissimi, accedi o registrati! </p>
            <button nz-button type="button" nzSize="large" nzType="primary" routerLink="/registrazione" class="bottone">Accetto</button>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-button type="button" nzSize="large" class="bottone" href="javascript:window.open('','_self').close();">Rifiuto</a>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">            
            Sei genitore o tutore di {{dati?.cognome}} {{dati?.nome}}?
          </button>
        </h2>
        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="mb-4">Hai ricevuto questo invito da parte di <strong>{{dati?.utente}}</strong> per autorizzare <strong>{{dati?.cognome}} {{dati?.nome}}</strong> ad entrare a far parte del gruppo <strong>{{dati?.nome_gruppo}}</strong> nel Shop dello SciClub Bussoleno. Per accedere a sconti e promozioni per SKI PASS e giornate di sport e divertimento sulla neve a prezzi scontatissimi, aggiungi i dati del minorenne!</p>
            <button nz-button type="button" nzSize="large"  nzType="primary" routerLink="/registrazione-minorenne" class="bottone">Accetto</button>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-button type="button" nzSize="large" class="bottone" href="javascript:window.open('','_self').close();">Rifiuto</a>        
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            Sei già registrato allo shop?
          </button>
        </h2>
        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">            
            <p class="mb-4">Effettua il login e nella sezione dei tuoi gruppi, potrai trovare l'invito al gruppo <strong>{{dati?.nome_gruppo}}</strong>, a cui potrai accettare o rifiutare!</p>
            <button nz-button nzSize="large" type="button" nzType="primary" routerLink="/login" class="bottone">Login</button>  
          </div>
        </div>
      </div>
    </div>
  </div>    
</div>