import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  error = '';
  loading: boolean = false;
  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;

  token!: string;
  id!: number;

  formReset!: FormGroup;

  constructor(private _api: ApiService, private _router: Router, private _route: ActivatedRoute, private _notification: NzNotificationService) { }

  ngOnInit(): void {

    // Rimuovo lo possibilità di scorrere la pagina
    document.getElementsByTagName('body')[0].classList.add('noscroll')

    // Creo il form con i controlli
    this.formReset = new FormGroup({
      password: new FormControl(null, Validators.required),
      confirm_password: new FormControl(null, Validators.required)
    })

    // Recupero i parametri passati nell'url
    this._route.queryParams.subscribe(params => {

      this.token = params['token'];

      // Se è present il token procedo
      if (this.token) {

        // Controllo se il token è valido
        this._api.checkTokenReset(this.token).subscribe({
          next: (data) => {
            if (!data.id)
              this._router.navigate(['/richiesta-password']);
            else
              this.id = data.id;
          },
          error: (err) => this._router.navigate(['/richiesta-password'])
        })
      } else {
        this._router.navigate(['/richiesta-password']);
      }
    })
  }

  enableScroll() {
    // Riabilito lo scroll
    document.getElementsByTagName('body')[0].classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._router.navigate(['/richiesta-password']);
  }

  onSubmit(): void {
    this.loading = true;
    this.error = '';

    let reset = {
      password: this.formReset.get('password')?.value,
      confirm_password: this.formReset.get('confirm_password')?.value
    }

    // Se le password sono diverse tra di loro non procedo
    if (reset.password !== reset.confirm_password) {
      this.error = 'Le password devono coincidere';
      this.loading = false;
    } else {
      this.loading = true;

      // Aggiorno la password
      this._api.reset(reset.password, this.id).subscribe({
        error: (err) => {
          this.error = err;
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
          this._router.navigate(['/login']);
          this._notification.create('success', 'Password aggiornata', "La password è stata aggiornata con successo!", { nzPlacement: 'bottomLeft', nzDuration: 10000 });
        }
      })
    }
  }
}