import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenImpersona } from '../shared/interface.model';
import { TokenStorageService } from '../services/token-storage.service';

@Component({
  selector: 'app-impersona',
  templateUrl: './impersona.component.html',
  styleUrls: ['./impersona.component.scss']
})
export class ImpersonaComponent implements OnInit {

  constructor(private _api: ApiService, private _token: TokenStorageService, private _router: Router, private _route: ActivatedRoute, private _location: Location) { }

  error: string = '';
  error_eta: boolean = false;
  loading: boolean = false;
  passwordVisible: boolean = false;

  token!: string;
  data_token!: TokenImpersona;

  formImpersona!: FormGroup;

  ngOnInit(): void {

    // Rimuovo lo possibilità di scorrere la pagina
    document.getElementsByTagName('body')[0].classList.add('noscroll')

    // Creo il form con i controlli
    this.formImpersona = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    })

    // Recupero i parametri passati nell'url
    this._route.queryParams.subscribe(params => {

      this.token = params['token'];

      // Se c'è un token procedo
      if (this.token) {

        // Controllo che il token sia valido
        this._api.chekTokenImpersona(this.token).subscribe({
          next: (data) => {
            if (!data.email) {
              this._router.navigate(['/']);
            } else {

              // Salvo i dati del token
              this.data_token = data;
              this.formImpersona.patchValue({ email: data.email })
            }
          },
          error: (err) => this._router.navigate(['/'])
        })
      } else
        this._router.navigate(['/']);
    })
  }

  enableScroll() {
    // Riabilito lo scroll
    document.getElementsByTagName('body')[0].classList.remove('noscroll')
  }

  onGoBack() {
    this.enableScroll();
    this._location.back();
  }

  onSubmit(): void {
    this.loading = true;
    this.error = '';

    let impersona = {
      email: this.formImpersona.get('email')?.value,
      username: this.formImpersona.get('username')?.value,
      password: this.formImpersona.get('password')?.value,
    }

    // Passo le credenziali del gestionale per impersonare l'utente con l'email passata
    this._api.impersona(impersona.email, impersona.username, impersona.password).subscribe({
      next: (login) => {
        this.loading = false;

        // Se l'utente è minorenne non procedo
        if (login.data.eta < 18) {
          this.error_eta = true;
        } else {
          this.enableScroll();
          this._token.setToken(login.token);
          this._token.setUser(login.data);
          this._router.navigate(['/']);
        }
      },
      error: (err) => {
        this.error = err;
        this.loading = false;
      }
    })
  }

}