<ng-template #indicatorTemplate>
  <img src="../../assets/img/riding-skiing.gif" alt="" width="250px">
</ng-template>
<nz-spin [nzSpinning]="caricamento" [nzIndicator]="indicatorTemplate" class="spinning spinner">
  <div class="container border p-0">
    <div class="row p-lg-4 p-1 py-3 m-0">
      <div class="col">
        <h4 class="mb-2">Cassa</h4>
        <p class="pb-0 mb-0">Completa le informazioni richieste e seleziona il metodo di pagamento!</p>
      </div>
    </div>
    <div class="row border-top border-bottom m-0 p-0">
      <ng-container *ngIf="step == 0">
        <div class="p-3 m-0 col-lg-4 border-end">
          <form [formGroup]="formUtente" class="p-lg-3 p-0">
            <div class="row mb-3">
              <div class="col">
                <div [ngStyle]="{'background-image':'url('+ img_profilo +')'}" class="rounded-circle border" style="background-size:cover; height: 104px; aspect-ratio: 1/1; margin: 0px auto;"></div>
              </div>
            </div>
            <div class="row" *ngIf="img_profilo != '../../assets/img/avatar.jpg'">
              <div class="col text-center">
                <a nz-popconfirm nzPopconfirmTitle="Sicuro di voler eliminare l'immagine?" (nzOnConfirm)="onRimuoviImmagine()" class="text-muted small text-center">Elimina</a>
              </div>
            </div>
            <div class="row mb-2 mt-3">
              <div class="col-md-6"><input formControlName="nome" nz-input placeholder="Nome" /></div>
              <div class="col-md-6  mt-md-0 mt-2"><input formControlName="cognome" nz-input placeholder="Cognome" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col"><input formControlName="email" nz-input placeholder="Email" /></div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <nz-date-picker formControlName="data_nascita" nzFormat="dd-MM-yyyy" class="w-100 data_nascita">
                </nz-date-picker>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col"><input type="text" formControlName="codice_fiscale" nz-input placeholder="Codice fiscale" /></div>
            </div>
            <div class="row mt-3" *ngIf="informazione.immagine">
              <nz-upload [(nzFileList)]="fileList" [nzShowButton]="fileList.length <= 0" [nzBeforeUpload]="beforeUpload" nzAccept="image/*" [nzRemove]="removeUpload">
                <button nzSize="large" nz-button type="button" style="width: 100%">
                  <span class="align-middle" nz-icon nzType="upload"></span>
                  Carica immagine
                </button>
              </nz-upload>
            </div>
          </form>
        </div>
        <div class="p-3 m-0 col-lg-8 d-flex align-items-center">          
          <div class="form-container w-100 p-3 info-container">
            <form [formGroup]="formInformazioni">
              <h4 class="mb-4">Informazioni</h4>

              <ng-template #codiceFiscaleAlert>
                <p class="mb-0">Per procedere, compila il campo 'Codice fiscale' nella pagina <strong><a routerLink="/profilo">Il mio account</a></strong></p>
              </ng-template>
              <nz-alert *ngIf="informazione.codice_fiscale" nzType="error" nzShowIcon [nzMessage]="codiceFiscaleAlert" [ngStyle]="{ marginBottom: '12px' }"></nz-alert>

              <div class="info-immagine" *ngIf="informazione.immagine">
                <nz-alert nzType="error" nzShowIcon nzMessage="Caricare un'immagine profilo per acquistare i prodotti della vialattea!" [ngStyle]="{ marginBottom: '12px' }"></nz-alert>
                <nz-alert nzType="error" nzShowIcon [nzMessage]="'Inserire un file di tipo PNG, JPG o JPEG com immagine di profilo'" class="mt-2 mb-2" *ngIf="error_image"></nz-alert>
              </div>
              <div class="info-ritiro" *ngIf="informazione.ritiro">
                <p class="titolo-info">Inserire un punto di ritiro per ritirare le tessere:</p>
                <nz-radio-group formControlName="ritiro">
                  <label nz-radio *ngFor="let punto_ritiro of punti_ritiro" [nzValue]="punto_ritiro.valore">{{punto_ritiro.descrizione}}</label>
                </nz-radio-group>
              </div>

              <div class="info-tesseramento mt-3" *ngIf="informazione.tesseramento">
                <p class="titolo-info">Modulo di accettazione e presa visione del MOG e codice Condotta:</p>                
                <label nz-checkbox class="mb-2" formControlName="tesseramento">* Dichiaro di aver preso visione e letto le direttive del
                  <a style="font-size: 14px;" href="#" (click)="onPdfFile('/assets/pdf/mog 2024.pdf')" target="_blank">MOG</a>
                  <span style="font-size: 14px;"> e del </span>
                  <a style="font-size: 14px;" href="#" (click)="onPdfFile('/assets/pdf/codice-condotta.pdf')" target="_blank">Codice di condotta</a>
                </label> 
              </div>              

              <div class="info-assicurazione mt-3" *ngIf="informazione.assicurativi.length > 0">
                <p class="titolo-info">Modulo di accettazione e presa visione dell'assicurazione:</p>
                <div class="assicurazioni" *ngFor="let assicurazione of informazione.assicurativi">
                  <label nz-checkbox class="mb-2" [formControlName]="'assicurazione_' + assicurazione.id">* Dichiaro di aver preso visione e letto le direttive dell'<a style="font-size: 14px;" href="#" (click)="onPdf(assicurazione.id)" target="_blank">{{assicurazione.nome}}</a></label>
                </div>
              </div>

              <div class="giornalieri" *ngIf="informazione.giornalieri.length > 0">
                <nz-form-item class="flex-column mb-2" *ngFor="let prodotto of informazione.giornalieri">
                  <nz-form-label class="text-start" nzRequired [nzTooltipIcon]="captchaTooltipIcon" nzTooltipTitle="Il prodotto Giornaliero può essere acquistato per il giorno stesso, fino alle ore 8 del mattino!"> Scegli per quale giorno attivare il prodotto <strong class="ms-1">{{prodotto.nome}}</strong>
                  </nz-form-label>
                  <nz-form-control>
                    <nz-date-picker [formControlName]="'giornaliero_' + prodotto.id" class="w-100" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" [nzShowToday]="false" [nzDisabledDate]="_date.disableDateGiornalieri(prodotto.giorni, prodotto.inizio_validita, prodotto.fine_validita)">
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div class="giornalieri_specifici" *ngIf="informazione.giornalieri_specifici.length > 0">
                <nz-form-item class="flex-column mb-2" *ngFor="let prodotto of informazione.giornalieri_specifici">
                  <nz-form-label class="text-start" nzRequired [nzTooltipIcon]="captchaTooltipIcon" nzTooltipTitle="Il prodotto Giornaliero può essere acquistato per il giorno stesso, fino alle ore 8 del mattino!"> Scegli per quale giorno attivare il prodotto <strong class="ms-1">{{prodotto.nome}}</strong>
                  </nz-form-label>
                  <nz-form-control>
                    <nz-date-picker [formControlName]="'giornaliero_specifico_' + prodotto.id" class="w-100" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" [nzShowToday]="false" [nzDisabledDate]="_date.disableDateGiornalieriSpecifici(prodotto.date_specifiche_dal, prodotto.date_specifiche_al)">
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- 
              <div class="codici" *ngIf="informazione.codici.length > 0">
                <nz-form-item class="flex-column mb-2" *ngFor="let prodotto of informazione.codici">
                  <nz-form-label class="text-start" nzRequired> Inserisci il codice della tua tessera per il prodotto: <strong class="ms-1">{{prodotto.nome}}</strong>
                  </nz-form-label>
                  <nz-form-control>
                    <input [formControlName]="'codici_' + prodotto.id" nz-input placeholder="Codice card" nzSize="large" />
                  </nz-form-control>
                </nz-form-item>
              </div> -->
            </form>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 1">

        <div class="p-3 col-lg-4 border-end">
          <div class="form-container p-lg-3 p-0 metodi-container">            
            <form [formGroup]="formMetodo">
              <h4 class="mb-4 text-start">Metodo di pagamento</h4>
              <nz-radio-group formControlName="metodo">
                <label nz-radio class="mt-0 mb-2" *ngIf="metodo.carta" nzValue="Carta di credito">Carta di credito</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.carta" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Carta di Credito' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Carta di credito">Carta di credito</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="metodo.satispay" nzValue="Satispay">Satispay</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.satispay" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Satispay' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Satispay">Satispay</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="metodo.bonifico && informazione.giornalieri.length <= 0 && informazione.giornalieri_specifici.length <= 0 && codici_sconto.length <= 0" nzValue="Bonifico" nz-tooltip nzTooltipTitle="Attenzione! Selezionando l'opzione bonifico, i prodotti non saranno attivi finchè non verrà accertato l'avvenuto pagamento. Questo può richiedere fino a due giorni lavorativi. Puoi completare il pagamento nella sezione 'I miei ordini'." [nzTooltipColor]="'blue'">Bonifico <i class="text-muted bi bi-exclamation-triangle-fill"></i></label>
                <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.bonifico || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0 || codici_sconto.length > 0" [nzDisabled]="true" nz-tooltip nzTooltipTitle="Il metodo 'Bonifico' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top" nzValue="Bonifico">Bonifico</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="metodo.dopo && informazione.giornalieri.length <= 0 && informazione.giornalieri_specifici.length <= 0 && !informazione.skipass && codici_sconto.length <= 0" nzValue="Dopo" nz-tooltip nzTooltipTitle="Attenzione! Selezionando l'opzione 'Pagamento in seguito', i prodotti non saranno attivi finchè non verranno pagati!. Puoi completare il pagamento nella sezione 'I miei ordini'." [nzTooltipColor]="'blue'">Paga in seguito</label>
                <label nz-radio class="mt-0 mb-2" *ngIf="!metodo.dopo || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0 || informazione.skipass || codici_sconto.length > 0" [nzDisabled]="true" nzValue="Dopo" nz-tooltip nzTooltipTitle="Il metodo 'Paga in seguito' non può essere utilizzato perché alcuni prodotti nel carrello, non consentono questo metodo di pagamento" nzTooltipPlacement="top">Paga in seguito</label>
              </nz-radio-group>
            </form>
          </div>          
        </div>     
        
        <div class="p-3 m-0 col-lg-8 riepilogo-container">          
          <div class="p-lg-3 p-0">
            <h4 class="mb-4">Riepilogo</h4>            
            
            <ul class="list-group list-group rounded-0">                  
              <li class="list-group-item fw-bold text-white bg-primary">Prodotti</li>
              <li class="list-group-item" *ngFor="let prodotto of carrello.prodotti" nz-tooltip [nzTooltipTitle]="prodotto.supplemento ? 'Il prodotto ha avuto un supplemento perché è stato acquistato per il giorno seguente in queste fascie di orario: Dalle 21:01 alle 23:00 - 0,50€. Dalle 23:01 alle 8:00 - 2,00€': ''">{{prodotto.nome}} | {{prodotto.prezzo | currency: 'EUR'}} {{prodotto.supplemento ? ' + ' + (prodotto.supplemento | currency: 'EUR') : ''}}
                <span class="ms-2 fs-5 text-muted float-end delete cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di volere rimuovere il prodotto dal carrello?" nz-icon nzType="delete" nzTheme="outline" (nzOnConfirm)="onRimuoviProdotto(prodotto.id)"></span>
              </li>
              <li class="list-group-item fw-bold text-white bg-primary" *ngIf="codici_sconto.length > 0">Codici Sconto</li>
              <li class="list-group-item" *ngFor="let codice_sconto of codici_sconto"><span>Codice sconto: <strong>{{codice_sconto.codice}}</strong> - {{codice_sconto.valore | currency: 'EUR'}}</span>
                <span class="ms-2 fs-5 text-muted float-end delete cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di volere rimuovere il codice sconto dal carrello?" nz-icon nzType="delete" nzTheme="outline" (nzOnConfirm)="onRimuoviCodiceSconto(codice_sconto.id)"></span>
              </li>
              <li class="list-group-item fw-bold text-white bg-primary"><i style="visibility: hidden;">text</i></li>
              <li class="list-group-item" *ngIf="codici_sconto.length > 0"><strong>Totale Scontato</strong>: {{carrello.sub_totale | currency: 'EUR'}}</li>
              <!-- <li class="list-group-item" *ngIf="carrello.commissioni > 0"><strong>Commissioni</strong>: {{carrello.commissioni | currency: 'EUR'}}</li> -->
              <li class="list-group-item"><strong>Totale</strong>: <span [ngStyle]="{'text-decoration': codici_sconto.length <= 0 ? 'none' : 'line-through'}">{{(carrello.totale || 0) | currency: 'EUR'}}</span></li>                         
            </ul>                            

          </div>  
          
          <div class="p-lg-3 mt-lg-3 mt-5 p-0 codici-container">
            <form [formGroup]="formCodiceSconto" class="w-100">
              <div class="row">
                <div class="col">
                  <h4 class="mb-4">Hai un codice sconto? <span nz-icon class="info" nzType="info-circle" nzTheme="outline" [routerLink]="'/profilo'" nz-tooltip nzTooltipTitle="Clicca sull'icona e vai alla sezione 'Buoni Sconto' per vedere i codici sconto legati al tuo account"></span> </h4>            
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-10">
                  <input nz-input placeholder="Inserisci qui il tuo coupon" nzSize="large" formControlName="codice" />
                </div>
                <div class="col-md-2">
                  <button class="d-inline btn btn-primary rounded-0 w-100" nz-tooltip [nzTooltipTitle]="!formCodiceSconto.valid ? 'Inserire un codice sconto per applicarlo!' : ''" [disabled]="!formCodiceSconto.valid" type="button" (click)="onCoupon()">Applica</button>
                </div>
              </div>                            
            </form>          
          </div>
        </div>           
      </ng-container>
    </div>
    <div class="row m-0 p-0">
      <div class="col d-flex align-items-center justify-content-end checkout-desktop p-3">        
        <h6 *ngIf="step == 0" class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{(carrello.totale || 0)| currency: 'EUR'}} </h6>        
        <button *ngIf="step == 1 && (informazione.ritiro || informazione.tesseramento || informazione.immagine || informazione.assicurativi.length > 0 || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0 || informazione.codice_fiscale)" type="button" class="d-inline btn rounded-0 border me-3" (click)="stepZero(0)">Indietro</button>
        <button *ngIf="step == 1" class="d-inline btn btn-primary rounded-0" type="button" [disabled]="!formMetodo.valid && !gratis" nz-tooltip [nzTooltipTitle]="gratis || formMetodo.valid ? '' : 'Selezionare un metodo di pagamento per procedere con l\'ordine'" (click)="onPagamento()">Invia l'ordine</button>        
        <button *ngIf="step == 0" class="d-inline btn btn-primary rounded-0 me-3" type="button" [disabled]="!formInformazioni.valid || informazione.codice_fiscale" (click)="onProssimoStep()">Continua</button>
      </div>
      <div class="col checkout-mobile p-3">
        <h6 *ngIf="step == 0" class="my-auto d-inline me-3"><span class="fw-normal">Totale: </span>{{(carrello.totale || 0)| currency: 'EUR'}} </h6>
        <button *ngIf="step == 1 && (informazione.ritiro || informazione.tesseramento || informazione.immagine || informazione.assicurativi.length > 0 || informazione.giornalieri.length > 0 || informazione.giornalieri_specifici.length > 0 || informazione.codice_fiscale)" type="button" class="d-inline btn rounded-0 border col mb-0 bottone" (click)="stepZero(0)">Indietro</button>
        <button *ngIf="step == 1" class="d-inline btn btn-primary rounded-0 col mb-0 bottone" type="button" [disabled]="!formMetodo.valid && !gratis" nz-tooltip [nzTooltipTitle]="gratis || formMetodo.valid ? '' : 'Selezionare un metodo di pagamento per procedere con l\'ordine'" (click)="onPagamento()">Invia l'ordine</button>        
        <button *ngIf="step == 0" class="d-inline btn btn-primary rounded-0 col mb-0 bottone" type="button" [disabled]="!formInformazioni.valid || informazione.codice_fiscale" (click)="onProssimoStep()">Continua</button>
      </div>
    </div>
  </div>
</nz-spin>