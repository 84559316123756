<div class="auth-wrapper">
  <div class="form-container border position-relative p-5">
    <button style="font-size: 100%; position: absolute; top: 5px; left: 15px; background: none;" type="button" class="btn-close" aria-label="Close" (click)="onGoBack()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>        
    <button style="font-size: 80%; position: absolute; top: 15px; right: 15px" type="button" class="btn-close" aria-label="Close" (click)="enableScroll()" routerLink=".."></button>
    <div class="row d-flex mb-2 justify-content-center">
      <img class="logo" src="../../../assets/img/logo_short.png">
    </div>
    <h2>Reset password</h2>
    <i class="text-muted word-break text-center w-100">Inserisci la nuova password per il tuo account!</i>    
    <form [formGroup]="formReset" (submit)="onSubmit()">
      <nz-alert *ngIf="error" nzShowIcon nzType="error" [nzMessage]="error" [ngStyle]="{ 'margin-bottom': '16px' }"></nz-alert>
      <div class="input-container">        
        <nz-form-label nzFor="password" nzTooltipTitle="La password deve contenere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale"><span>* Password</span></nz-form-label>            
        <nz-input-group [nzSuffix]="suffixTemplate">
          <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" autocomplete />
        </nz-input-group>
        <ng-template #suffixTemplate>
          <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></span>
        </ng-template>
      </div>

      <div class="input-container">        
        <nz-form-label nzFor="confirm_password" nzTooltipTitle="La password deve contenere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale"><span>* Conferma Password</span></nz-form-label>            
        <nz-input-group [nzSuffix]="suffixConfirmTemplate">
          <input [type]="passwordConfirmVisible ? 'text' : 'password'" nz-input formControlName="confirm_password" autocomplete />
        </nz-input-group>
        <ng-template #suffixConfirmTemplate>
          <span nz-icon [nzType]="passwordConfirmVisible ? 'eye-invisible' : 'eye'" (click)="passwordConfirmVisible = !passwordConfirmVisible"></span>
        </ng-template>
      </div>

      <div class="cta-container">
        <button nz-button type="submit" nzType="primary" class="bottone" [disabled]="!formReset.valid"[nzLoading]="loading">Reimposta la tua password</button>
        <nz-divider></nz-divider>
        <div class="text-center mt-4">
          <span style="font-size: 90%;">Hai già un account? <a class="fw-bold text-decoration-none text-black" routerLink="/login">Effettua il login</a>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>