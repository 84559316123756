<div class="offcanvas offcanvas-end" tabindex="-1" id="carrello">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Carrello</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div *ngIf="carrello.prodotti.length === 0" id="empty" style="top:50%; transform: translateY(-50%)"
      class="position-relative text-center">
      <h6>Il carrello è vuoto</h6>
      <p>Sembra che tu non abbia ancora aggiunto nessun prodotto</p>
    </div>
    <ul class="list-group list-group-flush" >
      <li class="list-group-item border-bottom" style="margin-bottom: 15px;" *ngFor="let prodotto of carrello.prodotti">
        <div class="alert alert-danger small" *ngIf="prodotto.disabilitato == true">
          <i class="bi bi-info-circle me-2"></i>
          <span>Questo prodotto è già stato acquistato, devi rimuoverlo dal carrello per procedere alla cassa!</span>
        </div>
        <div class="cart-prodotto">
          <div>
            {{prodotto.nome}}<br />            
            <span class="fs-6 text-muted">{{prodotto.categoria}}</span><br />
            <p class="text-muted mb-2 fs-6">{{prodotto.descrizione ? prodotto.descrizione : 'Nessuna descrizione'}}</p>
            <span class="text-muted">{{prodotto.prezzo | currency: 'EUR'}}</span>
          </div>          
          <div class="ms-2 text-muted float-end delete" nz-icon nzType="delete" nzTheme="outline" (click)="removeCartItem(prodotto.id)"></div>        
        </div>                
      </li>
    </ul>
    <div class="row m-0 py-3 bg-white position-absolute" style="bottom: 0; z-index: 99; width: 100%;">
      <div class="row">
        <div class="col d-flex align-items-center ">
          <span>Totale: {{carrello.totale | currency: 'EUR'}}</span>
        </div>
        <div class="col">
          <button data-bs-dismiss="offcanvas" class="w-100 d-inline btn btn-primary rounded-0" [routerLink]="user ? '/cassa' : '/login'" [queryParams]="user ? {} : {section: 'cassa'}" (click)="_redirect.setTarget('cassa')" [disabled]="carrello.prodotti.length === 0">Vai alla cassa</button>
        </div>
      </div>
    </div>
  </div>
</div>