import { Component } from '@angular/core';

@Component({
  selector: 'app-annullo-pagamento',
  templateUrl: './annullo-pagamento.component.html',
  styleUrls: ['./annullo-pagamento.component.scss']
})
export class AnnulloPagamentoComponent {

  caricamento: boolean = false;

}