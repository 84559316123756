import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-floating-cart',
  templateUrl: './floating-cart.component.html',
  styleUrls: ['./floating-cart.component.scss']
})
export class FloatingCartComponent implements OnInit {

  constructor(public _cart: CartService, private _token: TokenStorageService) { }

  visible: boolean = false;

  user!: AuthUser;

  ngOnInit(): void {

    // Recupero i dati dell'utente salvati nel sessionStorage    
    this._token.subjectUser.subscribe((user) => {
      
      this.user = user == undefined ? this._token.getUser() : user;
      if (this.user)
        this.visible = true;
      else
        this.visible = false;      

    })
  }

}