import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthUser, Gruppo } from '../shared/interface.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-gruppi',
  templateUrl: './gruppi.component.html',
  styleUrls: ['./gruppi.component.scss']
})
export class GruppiComponent implements OnInit {

  gruppi: Gruppo[] = [];
  gruppi_aperti = new Set<number>();

  user?: AuthUser;
  loading: boolean = false;

  ricerca_gruppi: string = ''

  constructor(private _api: ApiService, private _token: TokenStorageService, private _notification: NzNotificationService) { }

  ngOnInit(): void {

    this.user = this._token.getUser();

    // Recupero i gruppi
    this._api.gruppiChanged.subscribe({
      next: (data) => {
        this.gruppi = [...data];

        // Ciclo tra i gruppi
        this.gruppi.map(gruppo => {

          // Apro i gruppi
          this.onExpandChange(gruppo.id, true);

          // Recupero i membri del gruppo
          this._api.getMembriGruppo(gruppo.id).subscribe({
            next: (data) => gruppo.membri = data,
            error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 })
          })
        })
      },
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 })
    })
  }

  onOrdinamentoNome(a: Gruppo, b: Gruppo) {
    return a.nome.localeCompare(b.nome)
  }
  
  onExpandChange(id: number, checked: boolean): void {
    if (checked)
      this.gruppi_aperti.add(id);
    else
      this.gruppi_aperti.delete(id);    
  }

  deleteGruppo(id: number) {

    // Elimino il gruppo
    this._api.deleteGruppo(id).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {
        this._api.setGruppi(this.user!.id);
        this.loading = false;
      }
    })
  }

}