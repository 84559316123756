<app-ordini-sospesi></app-ordini-sospesi>
<div class="container">
  <div class="row px-3">
    <div class="col">
      <h2 class="mb-3">Prodotti attivi</h2>
    </div>
  </div>  
  <div class="row px-3">
    <div class="col">
      <p>Tutti i tesseramenti, card, giornalieri e prodotti in corso di validità, da te acquistati.
        <br />Per vedere quelli dei tuoi gruppi, vai alla sezione <strong style="cursor: pointer;" routerLink="/gruppi">I MIEI GRUPPI</strong> e seleziona il gruppo del quale vuoi vedere gli ordini e i prodotti attivi dei suoi membri!
        <br />Se l'acquisto è stato fatto come membro di un GRUPPO, troverai l'icona <i class="fs-6 bi bi-people-fill"></i></p>
    </div>
  </div>    
  <div class="row mb-3 px-3" *ngIf="tesseramenti.length > 0">
    <div class="col mt-3 mb-3">
      <input nz-input type="text" placeholder="Cerca un prodotto..." [(ngModel)]="ricerca_attivi">
    </div>
  </div>
  <p *ngIf="tesseramenti.length < 1" class="text-center my-5 text-muted">Non hai nessun prodotto attivo...</p>
  <div class="row">
    <div class="col">
      <nz-table nzBordered class="px-3" *ngIf="tesseramenti.length > 0" #tesseramentiTable nzShowPagination [nzTotal]="(tesseramenti| searchTesseramenti: ricerca_attivi).length" nzShowSizeChanger [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 25, 50, 100]" [nzData]="tesseramenti | searchTesseramenti: ricerca_attivi">
        <thead>
          <tr>            
            <th [nzSortFn]="onOrdinamentoProdotto">Prodotto</th>
            <th [nzSortFn]="onOrdinamentoCodice" class="text-center">Codice</th>
            <th [nzSortFn]="onOrdinamentoPrezzo" class="text-center">Prezzo</th>
            <th [nzSortFn]="onOrdinamentoDataScadenza" class="text-center">Data scadenza</th>
            <th [nzSortFn]="onOrdinamentoData" class="text-end">Data d'acquisto</th>          
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tesseramento of tesseramentiTable.data| searchTesseramenti: ricerca_attivi">          
            <td>{{tesseramento.prodotto}} <i *ngIf="tesseramento.id_gruppo" [routerLink]="['/gruppi',tesseramento.id_gruppo]" class="cursor-pointer ms-3 fs-6 bi bi-people-fill" [nzTooltipTitle]="tesseramento.gruppo" nzTooltipPlacement="top" nz-tooltip></i></td>
            <td class="text-center">{{tesseramento.codice_tesseramento ? tesseramento.codice_tesseramento : '-'}}</td>
            <td class="text-center">{{tesseramento.prezzo | currency:'EUR'}}</td>
            <td class="text-center">{{tesseramento.id_categoria != 13 ? (tesseramento.fine_validita | date: 'dd/MM/yyyy') : (tesseramento.inizio_validita | date: 'dd/MM/yyyy') }}</td>
            <td class="text-end">{{tesseramento.data_creazione | date: 'dd/MM/yyyy HH:mm'}}</td>          
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>
<div class="container">
  <div class="row px-3">
    <div class="col">
      <h2 class="mb-3">Storico ordini</h2>
    </div>
  </div>  
  <div class="row px-3">
    <div class="col">
      <p>Tutti gli ordini che hai effettuato!
        <br />Se l'ordine è stato fatto come membro di un GRUPPO, troverai l'icona <i class="fs-6 bi bi-people-fill"></i>
      </p>  
    </div>
  </div>    
  <div class="row mb-3 px-3" *ngIf="ordini.length > 0">
    <div class="col mt-3 mb-3">
      <input nz-input type="text" placeholder="Cerca un prodotto..." [(ngModel)]="ricerca_ordini">
    </div>
  </div>  
  <p *ngIf="ordini.length < 1" class="text-center my-5 text-muted">Non hai nessun ordine...</p>
  <div class="row">
    <div class="col">
      <nz-table nzBordered class="px-3" #ordiniTable [nzData]="ordini | searchOrdini: ricerca_ordini" *ngIf="ordini.length > 0" nzShowPagination [nzTotal]="(ordini | searchOrdini: ricerca_ordini).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 25, 50]" nzShowSizeChanger>
        <thead>
          <tr>
            <th nzWidth="60px"></th>
            <th [nzSortFn]="onOrdinamentoOrdine" nzWidth="120px">N° ordine</th>          
            <th [nzSortFn]="onOrdinamentoProdotti" nzWidth="300px" class="text-start">Prodotto/i</th>
            <th [nzSortFn]="onOrdinamentoClienti" nzWidth="300px" class="text-start">Membro/i</th>
            <th [nzSortFn]="onOrdinamentoTotale" class="text-center">Totale</th>  
            <th [nzSortFn]="onOrdinamentoMetodo" class="text-center">Metodo</th>        
            <th [nzSortFn]="onOrdinamentoDataAcquisto" class="text-end">Data d'acquisto</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let ordine of ordiniTable.data | searchOrdini: ricerca_ordini">
            <tr>
              <td [nzShowExpand]="ordine.carrello.length > 1"  [nzExpand]="expandSet.has(ordine.id_ordine)" (nzExpandChange)="onExpandChange(ordine.id_ordine, $event)"></td>
              <td>{{ordine.id_ordine ? '#' + ordine.id_ordine : '-'}} <i *ngIf="ordine.id_gruppo" [routerLink]="['/gruppi',ordine.id_gruppo]" class="cursor-pointer ms-3 fs-6 bi bi-people-fill" [nzTooltipTitle]="ordine.gruppo" nzTooltipPlacement="top" nz-tooltip></i></td>
              <td class="text-start" nzBreakWord>{{ordine.prodotto}}</td>
              <td [ngStyle]="{'text-align': ordine.id_gruppo ? 'left' : 'center'}">{{ordine.id_gruppo ? (ordine.cliente | titlecase) : '-'}}</td>
              <td class="text-center">{{ordine.prezzo | currency: 'EUR'}}</td>
              <td class="text-center">{{ordine.metodo ? (ordine.metodo | titlecase) : '-' }}</td>
              <td class="text-end">{{ordine.id_ordine ? (ordine.data_creazione | date: 'dd/MM/yyyy HH:mm') : (ordine.inizio_validita | date: 'dd/MM/yyyy HH:mm') }}</td>
            </tr>

            <tr [nzExpand]="expandSet.has(ordine.id_ordine)">            

              <nz-table nzBordered class="px-3" #ordiniDettaglioTable [nzData]="ordine.carrello" [nzShowPagination]="false">
                <thead>
                  <tr>                    
                    <th [nzSortFn]="onOrdinamentoProdotti" class="text-start">Prodotto</th>
                    <th [nzSortFn]="onOrdinamentoClienti" class="text-start">Membro</th>
                    <th [nzSortFn]="onOrdinamentoTotale" class="text-center">Prezzo</th>                      
                  </tr>
                </thead>
                <tbody>                  
                    <tr *ngFor="let ordine_dettaglio of ordiniDettaglioTable.data">                      
                      <td class="text-start">{{ordine_dettaglio.prodotto}}</td>
                      <td [ngStyle]="{'text-align': ordine.id_gruppo ? 'left' : 'center'}">{{ordine.id_gruppo ? (ordine_dettaglio.cliente | titlecase) : '-'}}</td>
                      <td class="text-center">{{ordine_dettaglio.prezzo | currency: 'EUR'}}</td>                      
                    </tr>      
                </tbody>
              </nz-table>              

            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>
