<div class="container"> 
    <h2>Tesseramento Sci Club Bussoleno {{stagione.inizio_stagione}} / {{stagione.fine_stagione}}</h2>

    <div class="row">
        <div class="col text-center">
            <img src="../../../assets/img/tesseramento.jpeg" alt="Tesseramento" class="text-center my-5">
        </div>
    </div>    

    <p>Iscrivendoti al nostro Sci Club per la stagione invernale {{stagione.inizio_stagione}} / {{stagione.fine_stagione}} potrai accedere a tutte le nostre attività, sconti, convenzioni, promozioni.</p>
    <p>Per un maggiore rispetto dell’ambiente non emetteremo più una tessera plastificata, ma la ricevuta di tesseramento varrà come documento ufficiale di iscrizione.</p>
    <p>Potrai decidere di stamparlo o salvarlo sul tuo smartphone e presentarlo alle casse o nei locali/negozi convenzionati ove necessario.</p>

    <p>Tipi di tesseramenti:</p>
    <p><strong>Adulto: </strong>€ 15,00</p>
    <p><strong>Ragazzi: </strong>€ 10,00  (dai 6 ai 13 anni)</p>
    <p><strong>Baby: </strong>Gratis (per chi ha meno di 6 anni)</p>


    <!-- <div class="text-center mt-5">
        <h4>Promozione Novembre 2023</h4>
        <p>Per tutto il mese i prezzi del tesseramento sono i seguenti</p>
        <p><strong>Tesseramento Adulto: 13€</strong></p>
        <p><strong>Tesseramento Ragazzi: 9€ (dai 6 ai 13)</strong></p>
    </div> -->

</div>