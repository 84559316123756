<ng-template #indicatorTemplate>
    <img src="../../assets/img/riding-skiing.gif" alt="" width="250px">
</ng-template>
<nz-spin [nzSpinning]="caricamento" [nzIndicator]="indicatorTemplate" class="spinning spinner">
    <div class="container border p-0">        
        <h4 class="p-4 text-center">Pagamento annullato</h4>
        <p class="col text-center border-top mb-0" style="padding-top: 30px; padding-bottom: 30px;">Il pagamento è stato annullato!.<br />Per tornare allo homepage premi sul pulsante qua sottostante!</p>
        
        <div class="row p-3 border-top m-0">
            <div class="col d-flex align-items-center justify-content-end">
                <button nz-button nzSize="large" class="d-inline btn rounded-0 border" routerLink="/">Torna allo shop</button>
            </div>
        </div>
    </div>
</nz-spin>