<!-- desktop -->
<nav class="d-none d-lg-flex border-bottom">
  <div class="logo-wrapper" src="../../assets/img/logo_short.png" alt="">
    <a href="https://www.sciclubbussoleno.com/" target="_blank" (click)="closeAccount()">
      <img class="logo" src="../../assets/img/logo_short.png" alt="">
    </a>
  </div>
  <div class="link-wrapper" [ngStyle]="{'width': user?.id ? '40%' : '10%'}">
    <a routerLinkActive="link-active" routerLink="/" [routerLinkActiveOptions]="{exact: true}" (click)="closeAccount()" class="nav-link">Home</a>        
    <a routerLinkActive="link-active" class="nav-link" *ngIf="user?.id" (click)="toggleProdotti(); closeAccount()">Prodotti</a>    
    <a routerLinkActive="link-active" routerLink="skipass" class="nav-link" *ngIf="user?.id" (click)="closeAccount()">SkiPass Vialattea</a>    
    <a routerLinkActive="link-active" routerLink="gruppi" class="nav-link" *ngIf="user?.id" (click)="closeAccount()">Gruppi</a>    
    <a routerLinkActive="link-active" routerLink="ordini" class="nav-link" *ngIf="user?.id" (click)="closeAccount()">Ordini</a>
  </div>
  <div class="icon-wrapper">
    <span *ngIf="!user?.id" class="user-name mx-3" routerLinkActive="link-active" routerLink="/login">Accedi</span>
    <span *ngIf="user?.id" class="user-name mx-3" (click)="toggleAccount()">Ciao, {{user!.nome}} <span [ngStyle]="{'transform': accountAperto ? 'rotate(180deg)' : 'rotate(0deg)'}" class="align-middle" style="font-size: 12px; transition: 0.5s;" nz-icon nzType="down" nzTheme="outline"></span></span>
    <div *ngIf="user?.id" class="icona" style="width: 0px;" (click)="toggleAccount()">
      <!-- <div class="propic rounded-circle me-5" [ngStyle]="{'background-image':'url('+ propic +')'}">
      </div> -->
      <div class="user-dropdown border border-top-0 border-bottom-0" [ngStyle]="{'max-height': accountAperto ? '175px' : '0'}">
        <ul>
          <li class="dropdown-option" [routerLink]="url">Il mio account</li>          
          <li class="dropdown-option" routerLink="gruppi">I miei gruppi</li>
          <li class="dropdown-option" routerLink="ordini">I miei ordini</li>
          <li class="dropdown-option" class="border-bottom" (click)="logout()">Esci</li>
        </ul>
      </div>
    </div>
    <div class="icona me-4" (click)="_cart.openCart();" *ngIf="user?.id">
      <i nz-icon nzType="shopping-cart" class="cart">
        <div class="cart-counter">
          {{ carrello?.prodotti?.length || 0 }}
        </div>
      </i>
    </div>
  </div>
</nav>
<!-- mobile -->
<nav class="d-lg-none justify-content-between px-3">
  <div class="logo-wrapper" src="../../assets/img/logo_short.png" alt="">
    <a href="https://www.sciclubbussoleno.com/" target="_blank" (click)="closeBurger()">
      <img class="logo-mobile" src="../../assets/img/logo_short.png" alt="">
    </a>
  </div>
  <div class="icon-wrapper-mobile">
    <div class="icona me-3" (click)="_cart.openCart(); closeBurger()" *ngIf="user?.id">
      <i nz-icon nzType="shopping-cart" class="cart">
        <div class="cart-counter">
          {{ carrello?.prodotti?.length || 0 }}
        </div>

      </i>
    </div>
    <div class="icona" style="display: flex; justify-items: center; align-items: center" (click)="toggleBurger()">
      <!-- <i nz-icon [nzType]="burgerOpen ? 'close' : 'menu'" class="cart"></i> -->
      <i class="bi text-black" style="font-size: 30px;" [class]="burgerOpen ? 'bi-x-lg' : 'bi-list'"></i>
    </div>
  </div>
</nav>

<div class="backdrop" [ngStyle]="{'max-height': burgerOpen? '100vh' : '0'}">
  <div class="link-wrapper-mobile">
    <a (click)="toggleBurger()" routerLinkActive="link-active-mobile" routerLink="" [routerLinkActiveOptions]="{exact: true}" class="nav-link py-2">Home</a>
    <a *ngIf="user?.id" (click)="toggleProdotti(); toggleBurger()" routerLinkActive="link-active-mobile" class="nav-link py-2">Prodotti</a>    
    <a *ngIf="user?.id" (click)="toggleBurger()" routerLinkActive="link-active-mobile" routerLink="/skipass" class="nav-link py-2">SkiPass Vialattea</a>    
    <a *ngIf="user?.id" (click)="toggleBurger()" routerLinkActive="link-active-mobile" routerLink="/gruppi" class="nav-link py-2">Gruppi</a>
    <a *ngIf="user?.id" (click)="toggleBurger()" routerLinkActive="link-active-mobile" routerLink="/ordini" class="nav-link py-2">Ordini</a>
    <a *ngIf="user?.id" (click)="toggleBurger()" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="link-active-mobile" routerLink="profilo" class="nav-link py-2">Profilo</a>
    <a *ngIf="user?.id" (click)="logout()" routerLinkActive="link-active-mobile" class="nav-link py-2 mt-5">Esci</a>
    <a *ngIf="!user?.id" (click)="toggleBurger()" routerLinkActive="link-active-mobile" routerLink="/login" class="nav-link py-2">Accedi</a>
  </div>
</div>
<div class="user-dropdown-backdrop" [ngStyle]="{'display': accountAperto ? 'block' : 'none'}" (click)="closeAccount()"></div>
<app-offcanvas-cart></app-offcanvas-cart>