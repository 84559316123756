<div class="gruppo-container container">
    <div class="row">
        <div class="col">
            <i class="mt-3 bi bi-arrow-left fs-3 cursor-pointer" (click)="onGoBack()"></i>
        </div>
    </div>
    <h2 class="text-center">Creazione gruppo</h2>
    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
    <form [formGroup]="formGruppo" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col">
                <div class="input-container">
                    <label>* Nome</label>
                    <input nz-input type="text" formControlName="nome" />
                </div>
            </div>
        </div>     
        <div class="row">
            <div class="col">
                <div class="input-container">
                    <label>Descrizione <i>(opzionale)</i></label>
                    <textarea nz-input formControlName="descrizione"></textarea>
                </div>
            </div>
        </div>   
        
        <div class="row">
            <div class="col">
                <div class="input-container" style="padding-top: 10px">
                    <label>Immagine <i>(opzionale)</i></label><br />
                    <nz-upload [(nzFileList)]="fileList" [nzShowButton]="fileList.length <= 0" [nzBeforeUpload]="beforeUpload" nzAccept="image/*" >
                        <button nz-button type="button" nzSize="large">
                            <span nz-icon nzType="upload"></span>
                            Carica immagine gruppo
                        </button>
                    </nz-upload>
                </div>
            </div>
        </div>        
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6 cta-container">                
                <button nz-button type="submit" [nzLoading]="loading" [disabled]="!formGruppo.valid">Crea gruppo</button>                
            </div>
            <div class="col-md-3"></div>
        </div>        
    </form>
</div>