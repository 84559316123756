<div class="modal fade" id="tutorialGruppi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content rounded-0 border-0">
            <div class="modal-header">
                <h1 class="modal-title text-muted fs-5" id="staticBackdropLabel">Come funzionano i gruppi?</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5 pt-4">
                <div class="container-fluid">
                    <div class="row gx-2">
                        <div class="col">
                            <h5 class="fs-4 mb-3">
                                I gruppi ti permettono di acquistare prodotti per più persone effettuando un ordine
                                solo. <br />
                            </h5>
                            <p>
                                Anzichè inserire manualmente i dati di ogni persona, puoi invitare amici e familiari all'interno del tuo gruppo.<br />
                                Quando i partecipanti avranno accettato l'invito, potrai acquistare ski pass e prodotti per ciascuno di loro in modo rapido e con un unico pagamento, senza dover inserire ogni volta i dati anagrafici.
                                Solo il creatore del gruppo ha il potere di fare acquisti, invitare membri e modificare il gruppo.<br />
                            </p>
                            <p>L'invito è recapitato via email e non ha data di scadenza.
                                Se sei già registrato al portale, potrai accettare o rifiutare inviti di partecipazione nella tua pagina <a style="white-space: nowrap" href="" data-bs-dismiss="modal">i miei gruppi.</a></p>

                            <p>Giornata sulla neve con i tuoi amici? Acquista lo skipass a tutto il gruppo. <br />
                                La tua famiglia fa parte dello sciclub? Rinnova tutti i tesseramenti con un click solo.
                            </p>
                            <h6 class="mb-2">Legenda:</h6>
                            <ul>
                                <li class="d-flex align-content-center gap-2">
                                    <span class="fs-5" nz-icon nzType="crown" nzTheme="outline"></span>
                                    Identifica il capogruppo e il proprietario del gruppo
                                </li>
                                <li class="d-flex align-content-center gap-2">
                                    <span class="fs-5" nz-icon nzType="edit" nzTheme="outline"></span>
                                    Permette di modificare il gruppo e aggiungere membri
                                </li>
                                <li class="d-flex align-content-center gap-2">
                                    <span class="fs-5" nz-icon nzType="shopping-cart" nzTheme="outline"></span>
                                    Consente di acquistare prodotti per te e i membri del gruppo
                                </li>
                                <li class="d-flex align-content-center gap-2">
                                    <span class="fs-5" nz-icon nzType="delete" nzTheme="outline"></span>
                                    Permette di eliminare il gruppo e i suoi membri
                                </li>
                                <li class="d-flex align-content-center gap-2">
                                    <span class="fs-5" nz-icon nzType="info-circle" nzTheme="outline"></span>
                                    Visualizza i dati del gruppo e dei membri
                                </li>
                            </ul>
                            <div class="row g-1 mt-5">
                                <div class="col-md-6"><button class="w-100" nzSize="large" nz-button nzType="primary" data-bs-dismiss="modal" routerLink="./crea">Voglio creare un gruppo!</button>
                                </div>
                                <div class="col-md-6"><button class="w-100" nzSize="large" nz-button nzType="default" data-bs-dismiss="modal">Vai ai tuoi gruppi</button></div>
                            </div>
                        </div>
                        <div class="d-none d-md-block col-1"></div>
                        <div class="d-none d-md-block col-4 img-tutorial"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>