import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// NGZORRO //
import { NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { it_IT } from 'ng-zorro-antd/i18n';
import { it as IT } from 'date-fns/locale';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
// NGZORRO //

import { ApiInterceptor } from './services/api.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { ShopComponent } from './shop/shop.component';
import { GruppiComponent } from './gruppi/gruppi.component';
import { GruppoComponent } from './gruppi/gruppo/gruppo.component';
import { AddGruppoComponent } from './gruppi/add-gruppo/add-gruppo.component';
import { AccountComponent } from './account/account.component';
import { StoricoOrdiniComponent } from './storico-ordini/storico-ordini.component';
import { RichiestaResetPasswordComponent } from './auth/richiesta-reset-password/richiesta-reset-password.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { InformativaAssicurazioneComponent } from './informativa/informativa-assicurazione/informativa-assicurazione.component';
import { InformativaSkicardVialatteaComponent } from './informativa/informativa-skicard-vialattea/informativa-skicard-vialattea.component';
import { InformativaSkipassOpenComponent } from './informativa/informativa-skipass-open/informativa-skipass-open.component';
import { InformativaTesseramentoComponent } from './informativa/informativa-tesseramento/informativa-tesseramento.component';
import { CassaComponent } from './cassa/cassa.component';
import { EsitoPagamentoComponent } from './esito-pagamento/esito-pagamento.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SelezioneComponent } from './selezione/selezione.component';
import { AcquistoGruppoComponent } from './gruppi/acquisto-gruppo/acquisto-gruppo.component';
import { CassaGruppoComponent } from './gruppi/cassa-gruppo/cassa-gruppo.component';
import { MembroComponent } from './auth/membro/membro.component';
import { AnnulloPagamentoComponent } from './annullo-pagamento/annullo-pagamento.component';
import { RegistrazioneMinorenneComponent } from './auth/registrazione-minorenne/registrazione-minorenne.component';
import { SearchCategoriaPipe, SearchCodiceScontoPipe, SearchContattiPipe, SearchGruppiPipe, SearchMembriPipe, SearchOrdiniClientiPipe, SearchOrdiniGruppiPipe, SearchOrdiniPipe, SearchPipe, SearchTesseramentiMembriPipe, SearchTesseramentiPipe } from './shared/search.pipe';
import { OnlyActivePipe } from './shared/only-active.pipe';
import { PaginazionePipe } from './shared/paginazione.pipe';
import { OffcanvasCartComponent } from './offcanvas-cart/offcanvas-cart.component';
import { InvitiComponent } from './gruppi/inviti/inviti.component';
import { TutorialGruppiComponent } from './gruppi/tutorial-gruppi/tutorial-gruppi.component';
import { ImpersonaComponent } from './impersona/impersona.component';
import { MinorenniComponent } from './auth/minorenni/minorenni.component';
import { OrdiniSospesiComponent } from './storico-ordini/ordini-sospesi/ordini-sospesi.component';
import { EsitoPagamentoDopoComponent } from './esito-pagamento-dopo/esito-pagamento-dopo.component';
import { CassaNonPagatiComponent } from './cassa-non-pagati/cassa-non-pagati.component';
import { TerminiUsoComponent } from './informativa/termini-uso/termini-uso.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { FloatingCartComponent } from './home/floating-cart/floating-cart.component';
import { SkipassComponent } from './skipass/skipass.component';

registerLocaleData(localeIT, 'it');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,          
    FooterComponent,    
    ShopComponent,    
    ResetPasswordComponent,
    GruppiComponent,
    GruppoComponent,
    AddGruppoComponent,        
    AccountComponent,
    StoricoOrdiniComponent,    
    RichiestaResetPasswordComponent,
    InformativaTesseramentoComponent,
    InformativaAssicurazioneComponent,
    InformativaSkicardVialatteaComponent,
    InformativaSkipassOpenComponent,
    CassaComponent,
    EsitoPagamentoComponent,    
    NavbarComponent,    
    SelezioneComponent,
    AcquistoGruppoComponent,    
    CassaGruppoComponent, 
    MembroComponent, 
    AnnulloPagamentoComponent, 
    RegistrazioneMinorenneComponent, 
    SearchPipe, 
    OnlyActivePipe, 
    OffcanvasCartComponent, 
    SearchCategoriaPipe, 
    PaginazionePipe, 
    InvitiComponent,   
    TutorialGruppiComponent,     
    ImpersonaComponent,
    SearchTesseramentiPipe,
    SearchTesseramentiMembriPipe,
    SearchOrdiniGruppiPipe,
    SearchOrdiniClientiPipe,
    SearchOrdiniPipe,
    SearchGruppiPipe,
    SearchMembriPipe,
    SearchContattiPipe,
    SearchCodiceScontoPipe,
    MinorenniComponent,
    OrdiniSospesiComponent,
    EsitoPagamentoDopoComponent,
    CassaNonPagatiComponent,
    TerminiUsoComponent,
    PrivacyComponent,
    FloatingCartComponent,
    SkipassComponent    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,    
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,                
    NzButtonModule,
    NzDropDownModule,
    NzIconModule,
    NzInputModule,
    NzAlertModule,
    NzInputNumberModule,    
    NzSpinModule,
    NzNotificationModule,
    NzProgressModule,
    NzTableModule,
    NzStepsModule,
    NzDatePickerModule,
    NzPopconfirmModule,
    NzUploadModule,
    NzSelectModule,
    NzDividerModule,
    NzModalModule,
    NzRadioModule,
    NzCarouselModule,
    NzToolTipModule,
    NzPaginationModule,
    NzResultModule,
    NzCollapseModule,
    NzTabsModule,
    NzEmptyModule,
    NzCheckboxModule,
    NzListModule,
    NzFormModule,
    NzBackTopModule,
    NzBadgeModule,
    NzSegmentedModule
  ],
  providers: [    
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: NZ_DATE_LOCALE, useValue: IT },
    { provide: LOCALE_ID, useValue: "it" },
    { provide: DatePipe},
    { provide: NzMessageService}    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
