<div class="selezione" >
    <i class="bi bi-arrow-down scroll-cta" *ngIf="user"></i>
    <div class="meta" id="solo">
        <div class="overlay text-center cursor-pointer" (click)="user ? scroll() : login()">
            <!-- <img src="../../assets/img/banner_test.jpg" alt="" (click)="user ? scroll() : login()"> -->
            <!-- <h3 class="text-white" [class.mb-3]="!user">{{user ? 'Acquista per te' : 'Acquista per te e i tuoi famigliari' }}</h3>
            <p *ngIf="user">Acquista prodotti solo per te</p>
            <button class="bottone" (click)="user ? scroll() : login()">{{user ? 'Vai al negozio' : 'Accedi per vedere i prodotti'}}</button> -->
        </div>
    </div>
    <div class="meta" [id]="user ? 'group' : 'group-no-login'">
        <div class="overlay text-center cursor-pointer" [routerLink]="user ? '/gruppi' : '/login'" [queryParams]="user ? {} : {section: 'gruppi'}">
            <!-- <img [src]="user ? '../../assets/img/banner_sciclub_gruppo_2.jpeg' : '../../assets/img/banner_sciclub_gruppo.jpeg'" alt="" [routerLink]="user ? '/gruppi' : '/login'" [queryParams]="user ? {} : {section: 'gruppi'}"> -->
            <!-- <h3 class="text-white">Acquista per un gruppo</h3>
            <p>{{user ? 'Acquista prodotti per te, i membri di un tuo gruppo e la tua famiglia ' : 'Accedi o registrati per acquistare prodotti per un gruppo o per la tua famiglia'}}</p>
            <button class="bottone" [routerLink]="user ? '/gruppi' : '/login'" [queryParams]="user ? {} : {section: 'gruppi'}">{{user ? 'Vai ai gruppi' : 'Accedi'}}</button> -->
        </div>
    </div>
</div>