import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private _router: Router, private _token: TokenStorageService, private _api: ApiService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {    
    return new Observable<boolean>(obs => {      
      this._api.isAuthorized().subscribe(res => {               
        if (!res) {
          this._router.navigateByUrl("");
          obs.next(false);
        }
        else {
          obs.next(true);
        }
      }, err => {
        this._router.navigateByUrl("");
      });
    });
  }
}
