<div class="auth-wrapper">
    <div class="form-container border position-relative p-5" #form>
        <button style="font-size: 100%; position: absolute; top: 5px; left: 15px; background: none;" type="button" class="btn-close" aria-label="Close" (click)="onGoBack()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>        
        <button style="font-size: 80%; position: absolute; top: 15px; right: 15px" type="button" class="btn-close" aria-label="Close" (click)="onClose()" routerLink=".."></button>
        <div class="row d-flex mb-2 justify-content-center">
            <img class="logo" src="../../../assets/img/logo_short.png">
        </div>
        <h2>Shop SciClub Bussoleno utente minorenne</h2>        
        <nz-alert *ngIf="errorMessage" nzShowIcon nzType="error" [nzMessage]="errorMessage" class="mt-3 mb-3"></nz-alert>
        <form [formGroup]="formRegistrazione" (ngSubmit)="onSubmit()" class="mt-3">            
            <div class="row">
                <div class="col-md-6">
                    <div class="input-container">
                        <label for="cognome">* Cognome del minore</label>
                        <input nz-input name="cognome" placeholder="es: Rossi" formControlName="cognome" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-container">
                        <label for="nome">* Nome del minore</label>
                        <input nz-input name="nome" placeholder="es: Mario" formControlName="nome" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="input-container">
                        <label for="codice_fiscale">* Codice Fiscale del minore</label>
                        <input nz-input name="codice_fiscale" formControlName="codice_fiscale" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-container">
                        <label for="data_nascita">* Data di nascita del minore</label>
                        <nz-date-picker [nzDisabledDate]="disabledDate" formControlName="data_nascita" nzPlaceHolder="es: 20/12/2023" [nzMode]="'date'" [nzFormat]="'dd/MM/yyyy'"></nz-date-picker>
                    </div>
                </div>
            </div>

            <div class="row">                
                <div class="col-md-6">
                  <div class="input-container">            
                    <label for="comune_nascita">* Comune di nascita del minore</label>
                    <input nz-input name="comune_nascita" formControlName="comune_nascita" />
                  </div>
                </div>              
                <div class="col-md-6">
                  <div class="input-container">
                    <label for="provincia_nascita">* Provincia di nascita del minore</label>
                    <input nz-input name="provincia_nascita" formControlName="provincia_nascita" />
                  </div>
                </div>   
            </div>

            <div class="row">
                <div class="col-md-6">
                  <div class="input-container">
                    <label for="nazione_nascita">* Nazione di nascita del minore</label>
                    <input nz-input name="nazione_nascita" formControlName="nazione_nascita" />
                  </div>
                </div>               
                <div class="col-md-6">
                  <div class="input-container">
                    <label for="indirizzo">* Indirizzo di residenza del minore</label>
                    <input nz-input name="indirizzo" formControlName="indirizzo" />
                  </div>             
                </div>                                 
            </div>  

            <div class="✅ mt-3">
                <div class="checkbox-container">                                        
                    <label nz-checkbox formControlName="privacy">* Accetto i termini e uso della <span routerLink="/privacy" style="color: #1890ff;">Privacy e Policy dello shop</span> </label>
                </div>
                <div class="checkbox-container">                    
                    <label nz-checkbox formControlName="tutela">* Dichiarazione di aver ricevuto delega da altro genitore e/o tutore</label>
                </div>
            </div>
            <div class="cta-container">
                <button nz-button type="submit" nzType="primary" class="bottone" [disabled]="!formRegistrazione.valid" [nzLoading]="loading">Registra il minore</button>
            </div>
        </form>
    </div>
</div>