<div class="gruppo-container container">
  <div class="row mb-3">
    <div class="col">
      <i class="mt-3 bi bi-arrow-left fs-3 cursor-pointer" (click)="onGoBack()"></i>
    </div>
  </div>

  <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>

  <nz-tabset>
    <nz-tab nzTitle="Informazioni generali">
      <h5 class="mt-3 mb-3">Dati del gruppo</h5>
      <form class="mb-5" [formGroup]="formGruppo" (ngSubmit)="onSubmit()">
        <div class="row flex-row-reverse align-items-center">
          <!-- immagine -->
          <div class="col-12 col-md-3 d-flex align-items-center text-center" style="flex-direction: column">
            <div class="border propic rounded-circle mx-auto d-flex justify-content-center align-items-end p-1" [ngStyle]="{'background-image':'url('+ propic +')'}"></div>
            <a nz-popconfirm nzPopconfirmTitle="Sicuro di voler eliminare l'immagine?" class="small mt-2" style="font-size: 14px;" (nzOnConfirm)="deleteImmagine()" *ngIf="gruppo?.immagine && buttonVisible">Elimina</a>
          </div>
          <div class="col">
            <!-- nome cognome -->
            <div class="row">
              <div class="col">
                <div class="input-container">
                  <label>* Nome</label>
                  <input nz-input type="text" required formControlName="nome" />
                </div>
              </div>
            </div>
            <!-- data luogo -->
            <div class="row">
              <div class="col">
                <div class="input-container">
                  <label>Descrizione <i *ngIf="buttonVisible">(opzionale)</i></label>
                  <textarea nz-input formControlName="descrizione"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="buttonVisible && !gruppo_default">
          <div class="input-container" style="padding-top: 10px">
            <label>Immagine <i>(opzionale)</i></label><br />
            <nz-upload [(nzFileList)]="fileList" [nzShowButton]="fileList.length <= 0" [nzBeforeUpload]="onUploadGruppo" nzAccept="image/*">
              <button nz-button type="button" nzSize="large">
                <span nz-icon nzType="upload"></span>
                Carica immagine gruppo
              </button>
            </nz-upload>
          </div>
        </div>
        <div class="row pt-0" *ngIf="buttonVisible && !gruppo_default">
          <div class="col-md-3"></div>
          <div class="col-md-6 cta-container">
            <button nz-button type="submit" nzType="primary" [nzLoading]="loading" [disabled]="!formGruppo.valid">Salva
              le modifiche</button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </form>
    </nz-tab>
    <nz-tab nzTitle="Membri">
      <h5 class="mt-3 mb-3" *ngIf="buttonVisible && !gruppo_default">Manda invito al gruppo {{gruppo?.nome}} ad un
        partecipante</h5>
      <form class="mb-2" [formGroup]="formMembro" *ngIf="buttonVisible && !gruppo_default">
        <p>Inserisci nome, cognome e email della persona che vuoi invitare. <br /> Se chi stai invitando non ha ancora compiuto 18 anni, inserisci l'indirizzo email di un genitore o tutore! <br /> Se non sai come fare, segui la nostra procedura per invitare un minorenne (<a target="_blank" href="../../../assets/pdf/tutorial.pdf">clicca qui</a>).</p>
        <div class="row">
          <div class="col-md-6">
            <div class="input-container">
              <label>* Cognome</label>
              <input nz-input formControlName="cognome" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-container">
              <label>* Nome</label>
              <input nz-input formControlName="nome" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-container">
              <label>* Email</label>
              <input nz-input type="email" formControlName="email" />
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3"></div>
          <div class="col-md-6 cta-container">
            <button nz-button (click)="addMembro()" type="button" nzType="primary" [nzLoading]="loadingMembro"
              nzSize="large" [disabled]="!formMembro.valid">Manda invito</button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </form>
      <h5 class="mt-3 mb-3">{{buttonVisible ? 'Gestisci i membri del gruppo' : 'Membri del gruppo'}}</h5>
      <p>{{buttonVisible ? 'Visualizza i membri del gruppo e rimuovi chi vuoi!' : 'Visualizza i membri del gruppo'}}</p>
      <div class="row mb-3" *ngIf="membri.length > 0">
        <div class="col mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un membro..." [(ngModel)]="ricerca_membri">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <nz-table nzBordered #membriTable [nzData]="membri | searchMembri: ricerca_membri" nzShowPagination
            [nzTotal]="(membri | searchMembri: ricerca_membri).length" [nzPageSize]="5"
            [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger>
            <thead>
              <tr>
                <th [nzSortFn]="onOrdinamentoMembro">Cognome e Nome</th>
                <th [nzSortFn]="onOrdinamentoEmail">Email</th>
                <th class="text-center">Azioni</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let membro of membriTable.data | searchMembri: ricerca_membri">
                <td>{{ membro.cognome }} {{membro.nome}}<i *ngIf="membro.id == gruppo!.id_cliente"
                    style="transform: translateY(-25%);" class="ms-2 fs-6" nz-icon nzType="crown" nzTheme="outline"></i>
                </td>
                <td>{{ membro.email }}</td>
                <td *ngIf="buttonVisible" class="text-center">                  
                  <a href="#" *ngIf="!membro.over18" data-bs-toggle="modal" data-bs-target="#modificaAnagrafica" (click)="onMembro(membro)" nzTooltipTitle="Modifica i dati del membro minorenne!" nzTooltipPlacement="top" nz-tooltip>
                    <span class="fs-4" nz-icon nzType="edit" nzTheme="outline"></span>
                  </a>                                    
                  <a nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler rimuovere il membro dal gruppo?." class="ms-3" (nzOnConfirm)="deleteMembro(membro.id)" *ngIf="membro.id !== this.user!.id" nzTooltipTitle="Rimuovi il membro dal gruppo!" nzTooltipPlacement="top" nz-tooltip>
                    <span class="fs-4" nz-icon nzType="minus-circle" nzTheme="outline"></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Buoni sconto">            
      <h5 class="my-3 mt-5 mb-3">Lista Buoni Sconto</h5>
      <p>Visualizza i buoni sconto per i membri del gruppo con il loro valore!</p>
      <div class="row mb-3" *ngIf="codici_sconto.length > 0">
        <div class="col mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un codice..." [(ngModel)]="ricerca_codici_sconto">
        </div>    
      </div>  
      <p *ngIf="codici_sconto.length < 1" class="text-center my-5 text-muted">Non hai associato nessun buono sconto...</p>
      <div class="row mt-2">
        <div class="col">
          <nz-table nzBordered #codiciTable *ngIf="codici_sconto.length > 0" [nzData]="codici_sconto | searchCodiceSconto: ricerca_codici_sconto" nzShowPagination [nzTotal]="(codici_sconto | searchCodiceSconto: ricerca_codici_sconto).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger class="table-codici">
            <thead>
              <tr>
                <th [nzSortFn]="onOrdinamentoCodiceSconto">Codice</th>
                <th [nzSortFn]="onOrdinamentoCodiceScontoMembro">Membro</th>
                <th [nzSortFn]="onOrdinamentoCodiceScontoValore">Valore</th>                               
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let codice_sconto of codiciTable.data | searchCodiceSconto: ricerca_codici_sconto">
                <tr>                  
                  <td>{{ codice_sconto.codice }}</td>                              
                  <td>{{ codice_sconto.cognome | titlecase }} {{ codice_sconto.nome | titlecase }}</td>
                  <td>{{ codice_sconto.valore | currency: 'EUR' }}</td>                  
                </tr>                
              </ng-container>
            </tbody>
          </nz-table>
        </div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Ordini e Prodotti" *ngIf="this.gruppo?.id_cliente == this.user!.id">
      <h5 class="mt-3 mb-3">Ordini</h5>
      <p>Gli ordini che hai effettuato per questo gruppo</p>
      <div class="row mb-3" *ngIf="ordini.length > 0">
        <div class="col-md-6 mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un membro..." [(ngModel)]="ricerca_ordini_membri">
        </div>
        <div class="col-md-6 mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un prodotto..." [(ngModel)]="ricerca_ordini_prodotti">
        </div>
      </div>
      <p *ngIf="ordini.length < 1" class="text-center my-5 text-muted">Non hai ancora effettuato nessun ordine...</p>
      <div class="row" *ngIf="ordini.length > 0">
        <div class="col">
          <nz-table nzBordered #ordiniGruppoTable
            [nzData]="ordini | searchOrdiniClienti: ricerca_ordini_membri | searchOrdini: ricerca_ordini_prodotti"
            nzShowPagination
            [nzTotal]="(ordini | searchOrdiniClienti: ricerca_ordini_membri | searchOrdini: ricerca_ordini_prodotti).length"
            [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger>
            <thead>
              <tr>
                <th [nzSortFn]="onOrdinamentoOrdine">N° ordine</th>
                <th [nzSortFn]="onOrdinamentoMembri" class="text-start">Membro</th>
                <th [nzSortFn]="onOrdinamentoProdotti" class="text-start">Prodotto/i</th>
                <th [nzSortFn]="onOrdinamentoTotale" class="text-center">Totale</th>
                <th [nzSortFn]="onOrdinamentoData" class="text-end">Data di acquisto</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let ordine of ordiniGruppoTable.data | searchOrdiniClienti: ricerca_ordini_membri | searchOrdini: ricerca_ordini_prodotti ">
                <td>#{{ordine.id_ordine}} <i *ngIf="false" class="ms-3 fs-6 bi bi-people"></i></td>
                <td class="text-start">{{ordine.cliente | titlecase}}</td>
                <td class="text-start">{{ordine.prodotto}}</td>
                <td class="text-center">{{ordine.prezzo | currency: 'EUR'}}</td>
                <td class="text-end">{{ordine.data_creazione | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>

      <h5 class="mt-3 mb-3">Prodotti</h5>
      <p>I prodotti VALIDI per i membri di questo gruppo</p>
      <div class="row mb-3" *ngIf="tesseramenti.length > 0">
        <div class="col-md-6 mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un membro..." [(ngModel)]="ricerca_tesseramenti_membri">
        </div>
        <div class="col-md-6 mt-3 mb-3">
          <input nz-input type="text" placeholder="Cerca un prodotto..." [(ngModel)]="ricerca_tesseramenti_prodotti">
        </div>
      </div>
      <p *ngIf="tesseramenti.length < 1" class="text-center my-5 text-muted">Nessun membro ha un prodotto valido ...</p>
      <div class="row" *ngIf="tesseramenti.length > 0">
        <div class="col">
          <nz-table nzBordered #tesseramentiGruppoTable
            [nzData]="tesseramenti | searchTesseramentiMembri: ricerca_tesseramenti_membri | searchTesseramenti: ricerca_tesseramenti_prodotti"
            nzShowPagination
            [nzTotal]="(tesseramenti | searchTesseramentiMembri: ricerca_tesseramenti_membri | searchTesseramenti: ricerca_tesseramenti_prodotti).length"
            [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger>
            <thead>
              <tr>                
                <th [nzSortFn]="onOrdinamentoMembri" class="text-start">Membro</th>
                <th [nzSortFn]="onOrdinamentoProdotto" class="text-start">Prodotto</th>
                <th [nzSortFn]="onOrdinamentoCodice">Codice Tesseramento</th>
                <th [nzSortFn]="onOrdinamentoPrezzo" class="text-center">Prezzo</th>
                <th [nzSortFn]="onOrdinamentoDataScadenza" class="text-end">Fine Validita</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let tesseramento of tesseramentiGruppoTable.data | searchTesseramentiMembri: ricerca_tesseramenti_membri | searchTesseramenti: ricerca_tesseramenti_prodotti ">                
                <td class="text-start">{{tesseramento.cliente | titlecase}}</td>
                <td class="text-start">{{tesseramento.prodotto}}</td>
                <td class="text-start">{{tesseramento.codice_tesseramento}}</td>
                <td class="text-center">{{tesseramento.prezzo | currency: 'EUR'}}</td>
                <td class="text-end">{{tesseramento.fine_validita | date: 'dd/MM/yyyy' }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>

      <h5 class="mt-3 mb-3">Ordina per il gruppo</h5>
      <p>Acquista prodotti per te e per i membri del tuo gruppo!</p>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 text-center cta-container">
          <button nz-button type="button" nzType="primary" [routerLink]="'/gruppi/' + gruppo?.id + '/acquisto'">
            Acquista </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Altro" *ngIf="!gruppo_default">
      <div class="elimina-gruppo" *ngIf="buttonVisible && !gruppo_default">
        <h5 class="mt-3 mb-3">Elimina il gruppo</h5>
        <p>Cancellando il gruppo cancellerai tutti i dati legati al gruppo. Questi includono anche i membri associati!
        </p>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center mt-3 cta-container">
            <button nzSize="large" nz-button nzDanger nzType="primary" type="button" class="w-100" [nzLoading]="loading"
              nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler eliminare il gruppo? Questa azione è irreversibile."
              (nzOnConfirm)="deleteGruppo(id_gruppo)"> Elimina gruppo</button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>

      <div class="esci-gruppo" *ngIf="this.gruppo?.id_cliente != this.user!.id">
        <h5 class="mt-3 mb-3">Esci dal gruppo</h5>
        <p>Uscendo dal gruppo, il capogruppo non potrà più acquistare prodotti per te! Per rientrare a far parte di
          questo gruppo dovrai essere invitato nuovamente.</p>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 text-center cta-container">
            <button nz-button type="button" nzDanger nzType="primary" nz-popconfirm
              nzPopconfirmTitle="Sei sicuro di voler uscire dal gruppo? Questa azione è irreversibile"
              (nzOnConfirm)="deleteMembro(this.user!.id)" [nzLoading]="loading"> Esci dal gruppo </button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </nz-tab>
  </nz-tabset>
</div>
<div class="modal fade" id="modificaAnagrafica" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content rounded-0">
      <div class="modal-header">
        <h5 class="modal-title">Modifica dati anagrafici</h5>
        <button type="button" class="btn-close" id="chiudiModale" data-bs-dismiss="modal" aria-label="Chiudi"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formUtente">
          <div class="container">

            <div class="row">                          
              <div class="mt-3 col-12 d-flex align-content-center text-center" style="flex-direction: column;">
                <div class="border propic rounded-circle mx-auto d-flex justify-content-start align-items-end p-1" [ngStyle]="{'background-image':'url('+ propicUtente +')'}"></div>
                <a class="small mt-2" style="font-size: 14px;" (click)="deleteImmagineUtente(membroSelezionato!.id)" *ngIf="membroSelezionato?.immagine_file">Elimina</a>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <nz-alert [nzType]="alertTypeMembro" nzShowIcon [nzMessage]="alertMessageMembro" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisibleMembro"></nz-alert>
              </div>
            </div>

            <div class="row mt-3">
              <div class="mt-2 col-md-6">
                <label>* Nome</label>
                <input nz-input formControlName="nome" />
              </div>
              <div class="mt-2 col-md-6">
                <label>* Cognome</label>
                <input nz-input formControlName="cognome" />
              </div>
            </div>
            <div class="row">
              <div class="mt-2 col-md-6">
                <label>* Data di nascita</label>
                <br>
                <nz-date-picker [nzDisabledDate]="disabledDate" nzFormat="dd/MM/yyyy" formControlName="data_nascita"></nz-date-picker>
              </div>
              <div class="mt-2 col-md-6">
                <label>* Comune di nascita</label>
                <input nz-input formControlName="comune_nascita">
              </div>
            </div>
            <div class="row">
              <div class="mt-2 col-md-6">
                <label>* Provincia di nascita</label>
                <input nz-input formControlName="provincia_nascita">
              </div>
              <div class="mt-2 col-md-6">
                <label>* Stato di nascita</label>
                <input nz-input formControlName="nazione_nascita">
              </div>
            </div>
            <div class="row">
              <div class="mt-2 col-md-6">
                <label>* Indirizzo di residenza</label>
                <input nz-input formControlName="indirizzo">
              </div>
              <div class="mt-2 col-md-6">
                <label>Comune di residenza <i>(opzionale)</i></label>
                <input nz-input formControlName="comune">
              </div>
            </div>
            <div class="row">
              <div class="mt-2 col-md-6">
                <label>Provincia di residenza <i>(opzionale)</i></label>
                <input nz-input formControlName="provincia">
              </div>
              <div class="mt-2 col-md-6">
                <label>Stato di residenza <i>(opzionale)</i></label>
                <input nz-input formControlName="nazione">
              </div>
            </div>
            <div class="row">
              <div class="mt-2 col-md-6">
                <label>* Codice Fiscale</label>
                <input nz-input formControlName="codice_fiscale">
              </div>
              <div class="mt-2 col-md-6">
                <label>Telefono <i>(opzionale)</i></label>
                <input nz-input formControlName="telefono">
              </div>
            </div>
            <div class="row">
              <div class="mt-2 col-12">
                <label>Immagine <i>(opzionale)</i></label><br>
                <nz-upload [(nzFileList)]="fileListUtente" [nzShowButton]="fileListUtente.length <= 0" [nzBeforeUpload]="onUploadMinorenne" nzAccept="image/*">
                  <button nzSize="large" nz-button type="button">
                    <span class="align-middle" nz-icon nzType="upload"></span>
                    Carica immagine profilo
                  </button>
                </nz-upload>                
                <span class="text-muted small mt-2">Nota: l'immagine deve ritrarre il volto del minorenne. Caricare
                  un'immagine di tipo diverso può invalidare il tesseramento e gli acquisti che effettui. Caricando una
                  foto ne garantisci la correttezza.</span>
              </div>              
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" nz-button nzSize="large" data-bs-dismiss="modal">Annulla</button>
        <button type="button" nz-button nzSize="large" nzType="primary" [disabled]="!formUtente.valid" (click)="onUpdateMembro()">Salva modifiche</button>
      </div>
    </div>
  </div>
</div>