<div class="modal fade" id="modalMinorenne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content rounded-0 border-0">
            <div class="modal-header">
                <h1 class="modal-title text-muted fs-5" id="staticBackdropLabel">Eta minore di 18 anni!</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5 pt-4">
                <div class="container-fluid">
                    <div class="row gx-2">
                        <div class="col">                            
                            <p>
                              A quanto pare non hai ancora compiuto 18 anni. <br />
                              Puoi comunque far parte di un gruppo di acquisto SKI PASS e PRODOTTI dello SCI CLUB BUSSOLENO! <br />
                              Chiedi a un tuo genitore/tutore/allenatore/maestro di sci di registrarsi allo SHOP e inserirti in un suo GRUPPO DI ACQUISTO!
                            </p>
                            <div class="row g-1 mt-3">
                                <div class="col"><button class="w-100" nzSize="large" nz-button nzType="primary" data-bs-dismiss="modal">Chiudi</button></div>                                
                            </div>
                        </div>                                                
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>