import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ShopComponent } from './shop/shop.component';
import { GruppiComponent } from './gruppi/gruppi.component';
import { GruppoComponent } from './gruppi/gruppo/gruppo.component';
import { AddGruppoComponent } from './gruppi/add-gruppo/add-gruppo.component';
import { AccountComponent } from './account/account.component';
import { StoricoOrdiniComponent } from './storico-ordini/storico-ordini.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RichiestaResetPasswordComponent } from './auth/richiesta-reset-password/richiesta-reset-password.component';
import { InformativaTesseramentoComponent } from './informativa/informativa-tesseramento/informativa-tesseramento.component';
import { InformativaAssicurazioneComponent } from './informativa/informativa-assicurazione/informativa-assicurazione.component';
import { InformativaSkicardVialatteaComponent } from './informativa/informativa-skicard-vialattea/informativa-skicard-vialattea.component';
import { InformativaSkipassOpenComponent } from './informativa/informativa-skipass-open/informativa-skipass-open.component';
import { CassaComponent } from './cassa/cassa.component';
import { EsitoPagamentoComponent } from './esito-pagamento/esito-pagamento.component';
import { AcquistoGruppoComponent } from './gruppi/acquisto-gruppo/acquisto-gruppo.component';
import { CassaGruppoComponent } from './gruppi/cassa-gruppo/cassa-gruppo.component';
import { MembroComponent } from './auth/membro/membro.component';
import { AnnulloPagamentoComponent } from './annullo-pagamento/annullo-pagamento.component';
import { RegistrazioneMinorenneComponent } from './auth/registrazione-minorenne/registrazione-minorenne.component';
import { ImpersonaComponent } from './impersona/impersona.component';
import { EsitoPagamentoDopoComponent } from './esito-pagamento-dopo/esito-pagamento-dopo.component';
import { CassaNonPagatiComponent } from './cassa-non-pagati/cassa-non-pagati.component';
import { TerminiUsoComponent } from './informativa/termini-uso/termini-uso.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SkipassComponent } from './skipass/skipass.component';
import { TesseramentoGuardService } from './services/tesseramento-guard.service';

const routes: Routes = [

  {
    path: '', component: ShopComponent, children: [
      {
        path: '', component: HomeComponent, data: { title: 'Home' }, children: [
          { path: 'login', component: LoginComponent, data: { title: 'Login' }},
          { path: 'registrazione', component: RegisterComponent, data: { title: 'Registrazione' } },
          { path: 'registrazione-minorenne', component: RegistrazioneMinorenneComponent, data: { title: 'Registrazione minorenne' } },
          { path: 'password', component: ResetPasswordComponent, data: { title: 'Reset password' } },
          { path: 'richiesta-password', component: RichiestaResetPasswordComponent, data: { title: 'Richiesta password' } },
          { path: 'membro', component: MembroComponent, data: { title: 'Richiesta aggiunta membro' } },
          { path: 'impersona', component: ImpersonaComponent, data: { title: 'Impersona' } }
        ]
      },         
      { path: 'esito-pagamento', component: EsitoPagamentoComponent, data: { title: 'Esito pagamento' }, children: [
        { path: 'login', pathMatch: 'full', component: LoginComponent, data: { title: 'Login' }},
      ]},     
      { path: 'skipass', component: SkipassComponent, canActivate: [AuthGuardService], data: { title: 'SkiPass Vialattea' } },                
      { path: 'profilo', component: AccountComponent, canActivate: [AuthGuardService], data: { title: 'Profilo' } },
      { path: 'gruppi', component: GruppiComponent, canActivate: [AuthGuardService], data: { title: 'Gruppi' } },
      { path: 'gruppi/crea', component: AddGruppoComponent, canActivate: [AuthGuardService], data: { title: 'Creazione gruppo' } },
      { path: 'gruppi/:id', component: GruppoComponent, canActivate: [AuthGuardService], data: { title: 'Gruppo' } },
      { path: 'gruppi/:id/acquisto', component: AcquistoGruppoComponent, canActivate: [AuthGuardService, TesseramentoGuardService], data: { title: 'Selezione prodotti gruppo' } },
      { path: 'gruppi/:id/cassa', component: CassaGruppoComponent, canActivate: [AuthGuardService, TesseramentoGuardService], data: { title: 'Cassa gruppo' } },      
      { path: 'cassa', component: CassaComponent, canActivate: [AuthGuardService, TesseramentoGuardService], data: { title: 'Cassa' } },
      { path: 'cassa-non-pagati', component: CassaNonPagatiComponent, canActivate: [AuthGuardService], data: { title: 'Cassa non pagati' } },      
      { path: 'esito-pagamento-dopo', component: EsitoPagamentoDopoComponent, canActivate: [AuthGuardService], data: { title: 'Esito pagamento in seguito' } },
      { path: 'annullo-pagamento', component: AnnulloPagamentoComponent, canActivate: [AuthGuardService], data: { title: 'Annullo pagamento' } },
      { path: 'ordini', component: StoricoOrdiniComponent, canActivate: [AuthGuardService], data: { title: 'Storico prodotti' } },
      { path: 'informativa-tesseramento', component: InformativaTesseramentoComponent, data: { title: 'Informativa tesseramenti' } },
      { path: 'informativa-assicurazione', component: InformativaAssicurazioneComponent, data: { title: 'Informativa assicurazione' } },
      { path: 'informativa-skicard-vialattea', component: InformativaSkicardVialatteaComponent, data: { title: 'Informativa Vialattea' } },
      { path: 'informativa-skipass-open', component: InformativaSkipassOpenComponent, data: { title: 'Informativa Skipass Open' } },
      { path: 'termini-condizioni', component: TerminiUsoComponent, data: { title: "Termini e condizioni d'uso" } },
      { path: 'privacy', component: PrivacyComponent, data: { title: "Privacy Policy" } },
    ]
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }