import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, Gruppo, Membri } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-add-gruppo',
  templateUrl: './add-gruppo.component.html',
  styleUrls: ['./add-gruppo.component.scss']
})
export class AddGruppoComponent implements OnInit {

  gruppo?: Gruppo;
  membri: Membri[] = [];
  id_gruppo!: string;

  user?: AuthUser;

  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;
  loading: boolean = false;

  file_immagine!: string;
  immagine!: string;

  fileList: NzUploadFile[] = [];

  formGruppo!: FormGroup;
  formMembro!: FormGroup;

  constructor(private _api: ApiService, private _token: TokenStorageService, private router: Router, private _location: Location, private _notification: NzNotificationService) { }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formGruppo = new FormGroup({
      nome: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

    // Recupero l'utente
    this.user = this._token.getUser();

  }

  beforeUpload = (file: any): boolean => {

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {

      this.alertMessage = "L'immagine del gruppo deve essere di tipo JPG, PNG o JPEG";
      this.alertVisible = true;
      this.alertType = 'error';
      return false;

    } else {

      this.fileList = this.fileList.concat(file);

      const reader = new FileReader();
      reader.onload = () => {
        this.immagine = file.name;
        this.file_immagine = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  }

  onGoBack() {
    this._location.back();
  }

  onSubmit() {

    let gruppo = {
      id_cliente: this.user!.id,
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.immagine || '',
      immagine_file: this.file_immagine || ''
    }

    // Aggiungo il gruppo
    this._api.addGruppo(gruppo).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => this.router.navigate(['/gruppi'])
    })

  }

}