import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthUser, Categorie, Gruppo, Login, Membri, Prodotto, Tesseramento, TokenGruppo, Utente, CarrelloMembro, Ordine, Fasce, Carrello, Invito, TokenImpersona, Contatto, CarrelloNonPagati, PuntoRitiro, CodiceSconto, CarrelloGruppo } from '../shared/interface.model';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = environment.apiUrl;
  private baseUrl = environment.baseUrl;

  constructor(private _http: HttpClient, private _modal: NzModalService) { }

  gruppi: Gruppo[] = [];

  gruppiChanged = new Subject<Gruppo[]>();

  setGruppi(id_cliente: number) {
    this._http.get<Gruppo[]>(`${this.apiUrl}gruppi/${id_cliente}`).subscribe(gruppi => {
      this.gruppi = gruppi;
      this.gruppiChanged.next(this.gruppi.slice());
    })
  }

  getCategorie() {
    return this._http.get<Categorie[]>(`${this.baseUrl}categorie`);
  }
  getCategoria(id: number) {
    return this._http.get<Categorie>(`${this.baseUrl}categorie/${id}`);
  }
  getProdotti(id?: number, eta?: number) {
    return this._http.get<Prodotto[]>(`${this.baseUrl}prodotti`, { params: { id: id || 0, eta: eta || 0 } });
  }
  getProdotto(id: number) {
    return this._http.get<Prodotto>(`${this.baseUrl}prodotti/${id}`);
  }
  getPDFAssicurazione(id_prodotto: number) {
    return this._http.get(`${this.apiUrl}pdf_assicurazione/${id_prodotto}`, { responseType: 'blob' });
  }
  getGruppi(id_cliente: number) {
    return this._http.get<Gruppo[]>(`${this.apiUrl}gruppi/${id_cliente}`);
  }
  getGruppo(id: number) {
    return this._http.get<Gruppo>(`${this.apiUrl}gruppo/${id}`);
  }
  getInviti(id_cliente: number) {
    return this._http.get<Invito[]>(`${this.apiUrl}inviti/${id_cliente}`);
  }
  getMembriGruppo(id_gruppo: number) {
    return this._http.get<Membri[]>(`${this.apiUrl}membri/${id_gruppo}`);
  }
  getUtente(id: number) {
    return this._http.get<Utente>(`${this.apiUrl}cliente/${id}`);
  }
  getContattiUtente(id_cliente: number) {
    return this._http.get<Contatto[]>(`${this.apiUrl}contatti/${id_cliente}`);
  }
  getContatto(id_contatto: number) {
    return this._http.get<Contatto>(`${this.apiUrl}contatto/${id_contatto}`);
  }
  getTesseramentiUtente(id_cliente: number) {
    return this._http.get<Tesseramento[]>(`${this.apiUrl}tesseramenti/${id_cliente}`);
  }
  getTesseramentiOrdiniUtente(id_cliente: number) {
    return this._http.get<{tesseramenti: Tesseramento[], ordini: Ordine[]}>(`${this.apiUrl}tesseramenti/${id_cliente}/ordini`);
  }
  getTesseramentiGruppo(id_gruppo: number) {
    return this._http.get<Tesseramento[]>(`${this.apiUrl}tesseramenti/${id_gruppo}/gruppo`);
  }
  getFasceProdotto(id_prodotto: number, eta: number) {
    return this._http.get<Fasce>(`${this.apiUrl}fasce_prodotto/${id_prodotto}`, { params: { eta: eta } });
  }
  getOrdiniUtente(id_cliente: number) {
    return this._http.get<Ordine[]>(`${this.apiUrl}ordini/${id_cliente}`);
  }
  getOrdiniGruppo(id_cliente: number, id_gruppo: number) {
    return this._http.get<Ordine[]>(`${this.apiUrl}ordini/${id_cliente}/gruppo/${id_gruppo}`);
  }
  getStagione() {
    return this._http.get<{ inizio_stagione: string, fine_stagione: string }>(`${this.baseUrl}stagione`);
  }
  getPuntiRitiro() {
    return this._http.get<PuntoRitiro[]>(`${this.baseUrl}punti_ritiro`);
  }
  getPagamentoCarta(ordine: { metodo: string, note: string, eseguitoDa: string, id_gruppo?: number, gruppo?: string, carrello: CarrelloMembro[], codici_sconto: CodiceSconto[] }) {
    return this._http.post<{ url: string, token_pagamento: string }>(`${this.apiUrl}pagamento_card`, ordine);
  }
  getPagamentoSatispay(ordine: { metodo: string, note: string, eseguitoDa: string, id_gruppo?: number, gruppo?: string, carrello: CarrelloMembro[], codici_sconto: CodiceSconto[] }) {
    return this._http.post<{ url: string, satispay: string }>(`${this.apiUrl}pagamento_satispay`, ordine);
  }
  getPagamentoCartaNonPagati(ordine: { metodo: string, carrello: CarrelloNonPagati }) {
    return this._http.post<{ url: string, token_pagamento: string }>(`${this.apiUrl}pagamento_card_non_pagati`, ordine);
  }
  getPagamentoSatispayNonPagati(ordine: { metodo: string, carrello: CarrelloNonPagati }) {
    return this._http.post<{ url: string, satispay: string }>(`${this.apiUrl}pagamento_satispay_non_pagati`, ordine);
  }
  getDettaglioPagamentoSatispay(satispay: string) {
    return this._http.post<{ status: string }>(`${this.apiUrl}dettagli_pagamento_satispay`, { satispay: satispay });
  }
  getCodiciScontoUtente(id_cliente: number) {
    return this._http.get<CodiceSconto[]>(`${this.apiUrl}codici_sconto/${id_cliente}`);
  }
  getCodiceScontoUtente(codice: string, id_cliente: number) {
    return this._http.get<CodiceSconto>(`${this.apiUrl}codice_sconto`, { params: { codice: codice, id_cliente: id_cliente } });
  }
  getCodiciScontoGruppo(id_gruppo: number) {
    return this._http.get<CodiceSconto[]>(`${this.apiUrl}codici_sconto_gruppo/${id_gruppo}`);
  }
  getCodiceScontoGruppo(codice: string, carrello: CarrelloGruppo) {
    return this._http.post<CodiceSconto>(`${this.apiUrl}codice_sconto_gruppo`, { codice: codice, carrello: carrello });
  }

  updateGruppo(id: number, gruppo: { nome: string, descrizione: string, immagine: string, immagine_file: string }) {
    return this._http.put(`${this.apiUrl}gruppo/${id}`, gruppo);
  }
  updateUtente(id: number, cliente: { nome: string, cognome: string, email: string, data_nascita: string, comune_nascita: string, provincia_nascita: string, nazione_nascita: string, indirizzo: string, comune: string | null, provincia: string | null, nazione: string | null, codice_fiscale: string, telefono: string | null, immagine: string | null, immagine_file: string | null,  newsletter: boolean }) {
    return this._http.put(`${this.apiUrl}cliente/${id}`, cliente);
  }
  updateUtenteMinorenne(id: number, cliente: { nome: string, cognome: string, data_nascita: string, comune_nascita: string, provincia_nascita: string, nazione_nascita: string, indirizzo: string, comune: string | null, provincia: string | null, nazione: string | null, codice_fiscale: string, telefono: string | null, immagine: string | null, immagine_file: string | null }) {
    return this._http.put(`${this.apiUrl}cliente_minorenne/${id}`, cliente);
  }
  updatePasswordUtente(id: number, password: { old_password: string, new_password: string, confirm_password: string }) {
    return this._http.put(`${this.apiUrl}cliente/${id}/password`, password);
  }
  updateCodiceFiscale(id: number, codice_fiscale: string) {
    return this._http.put(`${this.apiUrl}cliente/${id}/codice_fiscale`, { codice_fiscale: codice_fiscale});
  }
  updateContatto(id: number, contatto: { id_cliente: number, nome: string, cognome: string, email: string, telefono: string, parentela: string }) {
    return this._http.put(`${this.apiUrl}contatto/${id}`, contatto);
  }


  addRichiestaMembroGruppo(id_gruppo: number, membro: { cognome: string, nome: string, email: string, id_utente: number }) {
    return this._http.post(`${this.apiUrl}membro/${id_gruppo}`, membro)
  }
  addMembroGruppo(id_gruppo: number, id_cliente: number, id_cliente_invitato: number, id_invito?: number) {
    return this._http.post(`${this.baseUrl}membro`, { id_gruppo: id_gruppo, id_cliente: id_cliente, id_cliente_invitato: id_cliente_invitato, id_invito: id_invito })
  }
  addGruppo(gruppo: { id_cliente: number, nome: string, descrizione: string, immagine: string, immagine_file: string }) {
    return this._http.post(`${this.apiUrl}gruppo`, gruppo);
  }
  addOrdine(ordine: { metodo: string, nota: string, eseguitoDa: string, id_gruppo?: number | null, gruppo?: string | null, carrello: CarrelloMembro[], codici_sconto: CodiceSconto[], token: string | null, stato: string }) {
    return this._http.post<{ code: number }>(`${this.apiUrl}ordine`, ordine);
  }
  addOrdineNonPagati(ordine: { metodo: string, carrello: CarrelloNonPagati, token: string | null }) {
    return this._http.post<{ code: number }>(`${this.apiUrl}ordine_non_pagati`, ordine);
  }
  addContatto(contatto: { id_cliente: string, nome: string, cognome: string, email: string, telefono: string, parentela: string }) {
    return this._http.post(`${this.apiUrl}contatto`, contatto);
  }
  addInvito(invito: { id_cliente_invitato: string, id_cliente: string, id_gruppo: string }) {
    return this._http.post(`${this.apiUrl}invito`, invito);
  }


  deleteMembroGruppo(id_gruppo: number, id_membro: number) {
    return this._http.delete(`${this.apiUrl}membro/${id_gruppo}`, { params: { id_membro: id_membro } })
  }
  deleteGruppo(id_gruppo: number) {
    return this._http.delete(`${this.apiUrl}gruppo/${id_gruppo}`);
  }
  deleteUtente(id_utente: number) {
    return this._http.delete(`${this.apiUrl}cliente/${id_utente}`);
  }
  deleteImmagineUtente(id_utente: number) {
    return this._http.delete(`${this.apiUrl}immagine_cliente/${id_utente}`);
  }
  deleteImmagineGruppo(id_gruppo: number) {
    return this._http.delete(`${this.apiUrl}immagine_gruppo/${id_gruppo}`);
  }
  deleteInvito(id_invito: number) {
    return this._http.delete(`${this.apiUrl}invito/${id_invito}`);
  }
  deleteContatto(id_contatto: number) {
    return this._http.delete(`${this.apiUrl}contatto/${id_contatto}`);
  }

  checkMembroGruppo(id_utente: number, id_gruppo: number) {
    return this._http.post(`${this.apiUrl}check_membro_gruppo`, { id_gruppo: id_gruppo, id_utente: id_utente });
  }
  checkCarrello(id_utente: number, carrello: Carrello) {
    return this._http.post<{ prodotti: Prodotto[], esito: boolean }>(`${this.apiUrl}check_carrello`, { id_utente: id_utente, carrello: carrello });
  }
  checkTokenReset(token: string) {
    return this._http.post<{ id: number }>(`${this.baseUrl}check_token_reset_password`, { token: token });
  }
  checkTokenPagamento(token: string) {
    return this._http.post<any>(`${this.apiUrl}check_token_pagamento`, { token: token });
  }
  checkTokenPagamentoAexerve(token: string, token_pagamento: string) {
    return this._http.post<any>(`${this.apiUrl}check_token_pagamento_aexerve`, { token: token, token_pagamento: token_pagamento });
  }
  chekTokenGruppo(token: string) {
    return this._http.post<TokenGruppo>(`${this.baseUrl}check_token_gruppo`, { token: token });
  }
  chekTokenImpersona(token: string) {
    return this._http.post<TokenImpersona>(`${this.baseUrl}check_token_impersona`, { token: token });
  }


  isAuthorized() {
    return this._http.get<Boolean>(`${this.baseUrl}isAuthorized`);
  }
  login(email: string, password: string) {
    return this._http.post<Login>(`${this.baseUrl}login`, { email: email, password: password });
  }
  impersona(email: string, username: string, password: string) {
    return this._http.post<Login>(`${this.baseUrl}impersona`, { email: email, username: username, password: password });
  }
  registrazione(utente: { nome: string, cognome: string, email: string, confirm_email: string, password: string, confirm_password: string, data_nascita: string | null, comune_nascita: string, provincia_nascita: string, nazione_nascita: string, indirizzo: string | null, comune: string | null, provincia: string | null, nazione: string | null, codice_fiscale: string, telefono: string | null, newsletter: boolean, privacy: boolean }) {
    return this._http.post<{ id: number }>(`${this.baseUrl}registrazione`, utente);
  }
  registrazioneMinorenne(cliente: { nome: string, cognome: string, codice_fiscale: string, data_nascita: string | null, comune_nascita: string, provincia_nascita: string, nazione_nascita: string, indirizzo: string, privacy: boolean, parentela : boolean }) {
    return this._http.post<{ id: number }>(`${this.baseUrl}registrazione_minorenne`, cliente);
  }
  richiestaReset(email: string) {
    return this._http.post(`${this.baseUrl}richiesta_reset_password`, { email: email });
  }
  reset(password: string, id: number) {
    return this._http.post(`${this.baseUrl}reset_password`, { password: password, id: id });
  }  
}


@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private _token: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userToken = this._token.getToken();
    const user: AuthUser = this._token.getUser();

    const modifiedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${userToken} ${user.id}`),
    });
    return next.handle(modifiedReq).pipe(map(res => {
      return res;
    }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = `Errore - ${error.error.message}`;
        return throwError(errorMsg);
      })
    )
  }
}