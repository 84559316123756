<div class="auth-wrapper">
  <div class="form-container border position-relative p-5" #form>
    <button style="font-size: 100%; position: absolute; top: 5px; left: 15px; background: none;" type="button" class="btn-close" aria-label="Close" (click)="onGoBack()"><i style="font-size: 20px;" class="bi bi-arrow-left"></i></button>        
    <button style="font-size: 80%; position: absolute; top: 15px; right: 15px" type="button" class="btn-close" aria-label="Close" (click)="onClose()" routerLink=".."></button>
    <div class="row d-flex mb-2 justify-content-center">
      <img class="logo" src="../../../assets/img/logo_short.png">
    </div>
    <h2>Registrazione allo Shop SciClub Bussoleno</h2>    
    <nz-alert nzShowIcon *ngIf="error" nzType="error" [ngStyle]="{ 'margin-bottom': '16px' }" [nzMessage]="errore">
      <ng-template #errore>
        <p *ngIf="error == 'Email già in uso!'" style="margin-bottom: 0px;">Email già in uso, se sei già stato registrato in passato allo SciClub recupera la tua password, premendo <a routerLink="/password">QUI</a></p>
        <p *ngIf="error != 'Email già in uso!'" style="margin-bottom: 0px;">{{error}}</p>
      </ng-template>
    </nz-alert>
    <form [formGroup]="formRegistrazione" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">
          <div class="input-container">
            <label for="cognome">* Cognome</label>
            <input nz-input name="cognome" placeholder="es: Rossi" formControlName="cognome" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-container">
            <label for="nome">* Nome</label>
            <input nz-input name="nome" placeholder="es: Mario" formControlName="nome" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-container">
            <label for="email">* Email</label>
            <input nz-input type="email" name="email" placeholder="es: email@gmail.com" formControlName="email" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-container">
            <label for="confirmEmail">* Conferma email</label>
            <input nz-input name="confirmEmail" placeholder="es: email@gmail.com" formControlName="confirm_email" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-container">
            <nz-form-label nzFor="password" nzTooltipTitle="La password deve contenere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale"><span>* Password</span></nz-form-label>            
            <nz-input-group [nzSuffix]="suffixTemplate">
              <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password" autocomplete />
            </nz-input-group>
            <ng-template #suffixTemplate>
              <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></span>
            </ng-template>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-container">            
            <nz-form-label nzFor="confirmPassword" nzTooltipTitle="La password deve contenere almeno 8 caratteri, una lettera maiuscola, un numero e un carattere speciale"><span>* Conferma Password</span></nz-form-label>            
            <nz-input-group [nzSuffix]="suffixConfirmTemplate">
              <input [type]="passwordConfirmVisible ? 'text' : 'password'" nz-input formControlName="confirm_password" autocomplete />
            </nz-input-group>
            <ng-template #suffixConfirmTemplate>
              <span nz-icon [nzType]="passwordConfirmVisible ? 'eye-invisible' : 'eye'" (click)="passwordConfirmVisible = !passwordConfirmVisible"></span>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-container">            
            <label for="data_nascita">* Data di nascita</label>
            <nz-date-picker [nzDisabledDate]="disabledDate" formControlName="data_nascita" [nzMode]="'date'" nzPlaceHolder="es: 20/12/2023" [nzFormat]="'dd/MM/yyyy'"></nz-date-picker>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-container">            
            <label for="comune_nascita">* Comune di nascita </label>
            <input nz-input name="comune_nascita" formControlName="comune_nascita" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-container">
            <label for="provincia_nascita">* Provincia di nascita</label>
            <input nz-input name="provincia_nascita" formControlName="provincia_nascita" />
          </div>
        </div>   
        <div class="col-md-6">
          <div class="input-container">
            <label for="nazione_nascita">* Nazione di nascita</label>
            <input nz-input name="nazione_nascita" formControlName="nazione_nascita" />
          </div>
        </div>   
    </div>               
    <div class="row">   
        <div class="col-md-6">
          <div class="input-container">
            <label for="indirizzo">* Indirizzo di residenza</label>
            <input nz-input name="indirizzo" formControlName="indirizzo" />
          </div>             
        </div>           
        <div class="col-md-6">
          <div class="input-container">
            <label for="comune">Comune di residenza <i>(opzionale)</i></label>
            <input nz-input name="comune" formControlName="comune" />
          </div>                      
        </div>            
    </div>  
    <div class="row">
        <div class="col-md-6">
          <div class="input-container">
            <label for="provincia">Provincia di residenza <i>(opzionale)</i></label>
            <input nz-input name="provincia" formControlName="provincia" />
          </div>            
        </div>  
        <div class="col-md-6">
          <div class="input-container">
            <label for="nazione">Nazione di residenza <i>(opzionale)</i></label>
            <input nz-input name="nazione" formControlName="nazione" />
          </div>                               
        </div>                      
    </div>          
    <div class="row">
      <div class="col-md-6">
        <div class="input-container">
          <label for="codice_fiscale">* Codice Fiscale</label>
          <input nz-input name="codice_fiscale" formControlName="codice_fiscale" />
        </div>          
      </div>
      <div class="col-md-6">
        <div class="input-container">
          <label for="telefono">Telefono <i>(opzionale)</i></label>
          <input nz-input name="telefono" placeholder="es: 0000000000" formControlName="telefono" />            
        </div>          
      </div>            
    </div>

      <div class="✅ mt-3">
        <div class="checkbox-container">          
          <label nz-checkbox formControlName="newsletter">Accetto di ricevere mail dalla NewsLetter <i>(opzionale)</i></label>
        </div>
        <div class="checkbox-container">
          <label nz-checkbox formControlName="privacy">* Accetto i termini e uso della <span routerLink="/privacy" style="color: #1890ff;">Privacy e Policy dello shop</span> </label>
        </div>
      </div>
      <div class="cta-container">
        <button nz-button type="submit" nzType="primary" class="bottone" [nzLoading]="loading" [disabled]="!formRegistrazione.valid">Registrati</button>
      </div>
    </form>
    <div class="links" *ngIf="token_membro.length <= 0">
      <nz-divider></nz-divider>
      <div class="text-center mt-4">
        <span style="font-size: 90%;">Hai già un account? <a class="fw-bold text-decoration-none text-black" routerLink="/login">Effettua il login</a></span>
        <br />      
        <span style="font-size: 90%;">Altrimenti, se sei un utente del vecchio portale, <a class="fw-bold text-decoration-none text-black" routerLink="/password">clicca qui</a></span>      
      </div>
    </div>    
  </div>
</div>

<a #modal style="display: none;" data-bs-toggle="modal" data-bs-target="#modalMinorenne"></a>
<app-minorenni></app-minorenni>