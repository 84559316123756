import { Pipe, PipeTransform } from '@angular/core';
import { CodiceSconto, Contatto, Gruppo, Membri, Ordine, Prodotto, Tesseramento } from './interface.model';

@Pipe({
  name: 'search',
  pure: false
})
export class SearchPipe implements PipeTransform {
  transform(prodotti: Prodotto[], query: string): any {
    return prodotti.filter((prodotto: Prodotto) => prodotto.nome?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
  }
}
@Pipe({
  name: 'searchTesseramenti',
  pure: false
})
export class SearchTesseramentiPipe implements PipeTransform {
  transform(tesseramenti: any, query: string): any {
    return tesseramenti.filter((tesseramento: Tesseramento) => tesseramento.prodotto?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
  }
}

@Pipe({
  name: 'searchTesseramentiMembri',
  pure: false
})
export class SearchTesseramentiMembriPipe implements PipeTransform {
  transform(tesseramenti: any, query: string): any {
    return tesseramenti.filter((tesseramento: Tesseramento) => tesseramento.cliente?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
  }
}

@Pipe({
  name: 'searchContatti',
  pure: false
})
export class SearchContattiPipe implements PipeTransform {
  transform(contatti: any, query: string): any {
    return contatti.filter((contatto: Contatto) => (contatto.cognome.toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1 || contatto.nome.toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1))
  }
}

@Pipe({
  name: 'searchGruppi',
  pure: false
})
export class SearchGruppiPipe implements PipeTransform {
  transform(gruppi: any, query: string): any {
    return gruppi.filter((gruppo: Gruppo) => gruppo.nome?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
  }
}

@Pipe({
  name: 'searchMembri',
  pure: false
})
export class SearchMembriPipe implements PipeTransform {
  transform(membri: any, query: string): any {
    return membri.filter((membro: Membri) => (membro.cognome.toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1 || membro.nome.toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1))
  }
}

@Pipe({
  name: 'searchOrdini',
  pure: false
})
export class SearchOrdiniPipe implements PipeTransform {
  transform(ordini: any, query: string): any {
    return ordini.filter((ordine: Ordine) => ordine.prodotto?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
  }
}

@Pipe({
  name: 'searchOrdiniClienti',
  pure: false
})
export class SearchOrdiniClientiPipe implements PipeTransform {
  transform(ordini: any, query: string): any {
    return ordini.filter((ordine: Ordine) => ordine.cliente?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
  }
}


@Pipe({
  name: 'searchOrdiniGruppo',
})
export class SearchOrdiniGruppiPipe implements PipeTransform {

  transform(ordini: any, query: string): any {
    return ordini.filter((ordine: Ordine) => ordine.gruppo?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
  }

}

@Pipe({
  name: 'searchCategoria',
  pure: false
})
export class SearchCategoriaPipe implements PipeTransform {

  transform(prodotti: Prodotto[], categoria: number[]): any {
    if (!categoria || (Array.isArray(categoria) && categoria.length == 0 )) {
      return prodotti;
    }
    if (categoria.length == 1){
      return prodotti.filter((prodotto: Prodotto) => categoria[0] == prodotto.id_categoria);
    }
    return prodotti.filter((prodotto: Prodotto) => categoria.includes(prodotto.id_categoria));
  }

}

@Pipe({
  name: 'searchCodiceSconto',
  pure: false
})
export class SearchCodiceScontoPipe implements PipeTransform {
  transform(codici_sconto: any, query: string): any {
    return codici_sconto.filter((codice: CodiceSconto) => (codice.codice.toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1))
  }
}
