import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, CodiceSconto, Gruppo, Membri, Ordine, Tesseramento, Utente } from 'src/app/shared/interface.model';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-gruppo',
  templateUrl: './gruppo.component.html',
  styleUrls: ['./gruppo.component.scss']
})
export class GruppoComponent implements OnInit {

  public disabledDate = (current: Date): boolean => current > new Date();

  gruppo?: Gruppo;
  membri: Membri[] = [];
  id_gruppo!: number;
  ordini: Ordine[] = [];
  tesseramenti: Tesseramento[] = [];
  codici_sconto: CodiceSconto[] = [];

  user?: AuthUser;

  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  alertMessageMembro: string = '';
  alertTypeMembro: "success" | "info" | "warning" | "error" = 'success';
  alertVisibleMembro: boolean = false;

  buttonVisible: boolean = false;
  loading: boolean = false;
  loadingMembro: boolean = false;
  gruppo_default: boolean = false;

  modal: boolean = false;

  file_immagine!: string;
  immagine!: string;

  fileList: NzUploadFile[] = [];
  propic: string = '../../../assets/img/default-group.jpg';
  propicUtente: string = '../../assets/img/avatar.jpg';

  formGruppo!: FormGroup;
  formMembro!: FormGroup;
  formUtente!: FormGroup

  file_immagine_utente!: string;
  immagine_utente!: string;
  fileListUtente: NzUploadFile[] = [];

  membroSelezionato: Utente | Membri | undefined;

  ricerca_membri: string = '';
  ricerca_ordini_prodotti: string = '';
  ricerca_ordini_membri: string = '';
  ricerca_tesseramenti_prodotti: string = '';
  ricerca_tesseramenti_membri: string = '';
  ricerca_codici_sconto: string = '';

  modale: any;

  constructor(private _datePipe: DatePipe, private _api: ApiService, private _token: TokenStorageService, private _route: ActivatedRoute, private _router: Router, private _location: Location, private _notification: NzNotificationService) { }

  ngOnInit(): void {
    this.modale = new bootstrap.Modal(document.getElementById('modificaAnagrafica')!);

    // Creo il form con i controlli del gruppo
    this.formGruppo = new FormGroup({
      nome: new FormControl(null, Validators.required),
      descrizione: new FormControl(null)
    })

    // Creo il form con i controlli per i membri
    this.formMembro = new FormGroup({
      cognome: new FormControl(null, Validators.required),
      nome: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email])
    })

    // Creo il form con i controlli per l'utente minorenne
    this.formUtente = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      data_nascita: new FormControl(null, Validators.required),
      comune_nascita: new FormControl(null, Validators.required),
      provincia_nascita: new FormControl(null, Validators.required),
      nazione_nascita: new FormControl(null, Validators.required),
      indirizzo: new FormControl(null, Validators.required),
      comune: new FormControl(null),
      provincia: new FormControl(null),
      nazione: new FormControl(null),
      codice_fiscale: new FormControl(null, Validators.required),
      telefono: new FormControl(null)
    })

    this.getData();
  }

  getData() {

    // Recupero l'utente
    this.user = this._token.getUser();

    // Recupero i parametri passati nell'url
    this._route.params.subscribe(params => {
      this.id_gruppo = params['id'];

      // Recupero i dati del grupo
      this._api.getGruppo(this.id_gruppo).subscribe({
        next: (data) => this.gruppo = data,
        error: (err) => this._router.navigate(['/gruppi']),
        complete: () => {

          this.propic = '../../../assets/img/default-group.jpg';
          this.propic = this.gruppo!.immagine_file.length > 0 ? this.gruppo!.immagine_file : this.propic;

          // Se l'utente è proprietario mostro i pulsanti di modifica
          if (this.user!.id == this.gruppo!.id_cliente)
            this.buttonVisible = true;
          else
            this.formGruppo.disable();

          // Controllo se l'utente loggato è capogruppo e se è presente nel gruppo
          this._api.checkMembroGruppo(this.user!.id, +this.id_gruppo).subscribe({
            error: (err) => this._router.navigate(['/gruppi']),
            complete: () => {

              // Recupero i membri del gruppo
              this._api.getMembriGruppo(this.id_gruppo).subscribe({
                next: (data) => this.membri = [...data],
                error: (err) => this._router.navigate(['/gruppi']),
                complete: () => {

                  // Recupero gli ordini del gruppo
                  this._api.getOrdiniGruppo(this.user!.id, this.gruppo!.id).subscribe({
                    next: (data) => this.ordini = [...data],
                    error: (err) => this._router.navigate(['/gruppi']),
                    complete: () => {

                      // Recupero i tesseramenti dei membri del gruppo
                      this._api.getTesseramentiGruppo(this.gruppo!.id).subscribe({
                        next: (data) => this.tesseramenti = [...data],
                        error: (err) => this._router.navigate(['/gruppi']),
                        complete: () => {

                          // Recupero i codici sconto dei membri del gruppo
                          this._api.getCodiciScontoGruppo(this.gruppo!.id).subscribe({
                            next: (data) => this.codici_sconto = [...data],
                            error: (err) => this._router.navigate(['/gruppi']),
                            complete: () => {

                              this.formGruppo.setValue({
                                nome: this.gruppo?.nome,
                                descrizione: this.gruppo?.descrizione
                              })

                              // Ciclo per i membri
                              this.membri.map(membro => {

                                // Calcolo l'età e cerco di capire se è maggiorenne
                                let differenza = Math.abs(Date.now() - new Date(membro.data_nascita!).getTime());
                                let eta = Math.floor((differenza / (1000 * 3600 * 24)) / 365.25);
                                membro.over18 = eta >= 18;
                              })
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        }
      })
    })
  }

  onOrdinamentoMembro(a: Membri, b: Membri) {
    return a.cognome.localeCompare(b.cognome)
  }

  onOrdinamentoEmail(a: Membri, b: Membri) {
    return a.email.localeCompare(b.email)
  }

  onOrdinamentoOrdine(a: Ordine, b: Ordine) {
    return a.id_ordine - b.id_ordine;
  }

  onOrdinamentoMembri(a: Ordine, b: Ordine) {
    return a.cliente.localeCompare(b.cliente)
  }

  onOrdinamentoProdotti(a: Ordine, b: Ordine) {
    return a.prodotto.localeCompare(b.prodotto)
  }

  onOrdinamentoTotale(a: Ordine, b: Ordine) {
    return a.prezzo - b.prezzo;
  }

  onOrdinamentoData(a: Ordine, b: Ordine) {
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

  onOrdinamentoProdotto(a: Tesseramento, b: Tesseramento) {
    return a.prodotto.localeCompare(b.prodotto)
  }

  onOrdinamentoCodice(a: Tesseramento, b: Tesseramento) {
    return (a.codice_tesseramento || '').localeCompare((b.codice_tesseramento || ''))
  }

  onOrdinamentoPrezzo(a: Tesseramento, b: Tesseramento) {
    return a.prezzo - b.prezzo;
  }

  onOrdinamentoDataScadenza(a: Tesseramento, b: Tesseramento) {
    return a.fine_validita.toString().localeCompare(b.fine_validita.toString())
  }

  onOrdinamentoCodiceSconto(a: CodiceSconto, b: CodiceSconto) {
    return a.codice.localeCompare(b.codice);
  }

  onOrdinamentoCodiceScontoMembro(a: CodiceSconto, b: CodiceSconto) {
    return (a.cognome || '').localeCompare(b.cognome || '');
  }

  onOrdinamentoCodiceScontoValore(a: CodiceSconto, b: CodiceSconto) {
    return a.valore - b.valore
  }

  deleteMembro(id: number) {

    // Elimino il membro
    this._api.deleteMembroGruppo(+this.id_gruppo, id).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {

        this.membri = this.membri.filter(d => d.id !== id);

        if (this.gruppo?.id_cliente != this.user?.id)
          this._router.navigate(['/gruppi']);
        else
          this.onResult("Membro eliminato con successo", "success");

      }
    })
  }

  deleteGruppo(id: number) {

    // Elimino il gruppo
    this._api.deleteGruppo(id).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => this.onResult("Gruppo eliminato con successo", "success")
    })
  }

  deleteImmagine() {

    // Elimino l'immagine del gruppo
    this._api.deleteImmagineGruppo(+this.id_gruppo).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {
        this.gruppo!.immagine_file = '';
        this.gruppo!.immagine = '';
      }
    })
  }

  deleteImmagineUtente(id: number) {

    // Elimino l'immagine dell'membro
    this._api.deleteImmagineUtente(id).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {
        this.immagine_utente = '';
        this.file_immagine_utente = '';
        this.membroSelezionato!.immagine = '';
        this.membroSelezionato!.immagine_file = '';
        this.propicUtente = '';
      }
    })
  }

  onGoBack() {
    this._location.back();
  }

  onMembro(membro: Membri) {

    // Recupero i dati del membro selezionato
    this._api.getUtente(membro.id).subscribe({
      next: (data) => this.membroSelezionato = data,
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {
        this.formUtente.patchValue(this.membroSelezionato!);
        this.immagine_utente = this.membroSelezionato!.immagine;
        this.file_immagine_utente = this.membroSelezionato!.immagine_file;

        this.propicUtente = this.membroSelezionato!.immagine_file.length > 0 ? this.membroSelezionato!.immagine_file : this.propic;
        this.modal = true;
      }
    })
  }

  addMembro() {
    let membro = {
      email: this.formMembro.get('email')?.value,
      cognome: this.formMembro.get('cognome')?.value,
      nome: this.formMembro.get('nome')?.value,
      id_utente: this.user!.id
    }

    this.loadingMembro = true;

    // Aggiungo la richiesta di aggiunta membro 
    this._api.addRichiestaMembroGruppo(+this.id_gruppo, membro).subscribe({
      error: (err) => this.onResult(err, 'error'),
      complete: () => {
        this.formMembro.patchValue({ nome: '', cognome: '', email: '' })
        this.onResult("Un'email contenente l'invito è stata inviata all'indirizzo email inserito!", "success");
      }
    })
  }

  onUploadGruppo = (file: any): boolean => {
    this.fileList = this.fileList.concat(file);

    const isJpgOrPng = this.fileList.length > 0 ? this.fileList[0]!.type === 'image/jpeg' || this.fileList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.onResult("L'immagine del gruppo deve essere di tipo JPG, PNG o JPEG", "error");
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.immagine = file.name;
        this.file_immagine = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  onUploadMinorenne = (file: any): boolean => {
    this.fileListUtente = this.fileListUtente.concat(file);

    const isJpgOrPng = this.fileListUtente.length > 0 ? this.fileListUtente[0]!.type === 'image/jpeg' || this.fileListUtente[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.onResult("L'immagine del gruppo deve essere di tipo JPG, PNG o JPEG", "error");
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        this.immagine_utente = file.name;
        this.file_immagine_utente = reader.result as string;
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  onResult(message: string, type: any) {
    window.scrollTo(0, 0);
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = type;
    this.loading = false;
    this.loadingMembro = false
  }

  onResultMembro(message: string, type: any) {
    this.alertMessageMembro = message;
    this.alertVisibleMembro = true;
    this.alertTypeMembro = type;
  }

  onSubmit() {
    let gruppo = {
      nome: this.formGruppo.get('nome')?.value,
      descrizione: this.formGruppo.get('descrizione')?.value,
      immagine: this.immagine || '',
      immagine_file: this.file_immagine || ''
    }

    // Aggiorno i dati del gruppo
    this._api.updateGruppo(+this.id_gruppo, gruppo).subscribe({
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => {
        this.getData();
        this.onResult("Gruppo modificato con successo!", "success");
      }
    })
  }

  onUpdateMembro() {
    let membro = {
      nome: this.formUtente.get('nome')?.value,
      cognome: this.formUtente.get('cognome')?.value,
      data_nascita: this._datePipe.transform(this.formUtente.get('data_nascita')?.value, 'yyyy-MM-dd') || '',
      comune_nascita: this.formUtente.get('comune_nascita')?.value,
      provincia_nascita: this.formUtente.get('provincia_nascita')?.value,
      nazione_nascita: this.formUtente.get('nazione_nascita')?.value,
      indirizzo: this.formUtente.get('indirizzo')?.value,
      comune: this.formUtente.get('comune')?.value,
      provincia: this.formUtente.get('provincia')?.value,
      nazione: this.formUtente.get('nazione')?.value,
      codice_fiscale: this.formUtente.get('codice_fiscale')?.value,
      telefono: this.formUtente.get('telefono')?.value,
      immagine: this.immagine_utente || '',
      immagine_file: this.file_immagine_utente || ''
    }

    // Aggiorno i dati del membro minorenne
    this._api.updateUtenteMinorenne(this.membroSelezionato!.id, membro).subscribe({
      error: (err) => this.onResultMembro(err, 'error'),
      complete: () => {
        this.modal = false;
        document.getElementById('chiudiModale')?.click();
        this.getData();
      }
    })
  }

}