import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthUser, Invito } from 'src/app/shared/interface.model';

@Component({
  selector: 'app-inviti',
  templateUrl: './inviti.component.html',
  styleUrls: ['./inviti.component.scss']
})
export class InvitiComponent implements OnInit {

  constructor(private _api: ApiService, private _token: TokenStorageService, private _notification: NzNotificationService) { }

  inviti: Invito[] = [];

  user?: AuthUser;

  token: string = '';

  loading: boolean = false;

  ngOnInit(): void {

    this.user = this._token.getUser();
    this.token = this._token.getMembro();

    this.getData();
  }

  getData() {
    // Recupero gli inviti    
    this._api.getInviti(this.user!.id).subscribe({
      next: (data) => this.inviti = [...data],
      error: (err) => this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 }),
      complete: () => this._api.setGruppi(this.user!.id)
    })
  }

  onOrdinamentoInvitante(a: Invito, b: Invito) {
    return a.email_invitante.localeCompare(b.email_invitante)
  }

  onOrdinamentoGruppo(a: Invito, b: Invito) {
    return a.gruppo.localeCompare(b.gruppo)
  }

  onOrdinamentoData(a: Invito, b: Invito) {
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

  onAccept(id_invito: number, id_cliente: number, id_cliente_invitato: number, id_gruppo: number) {
    this.loading = true;

    // Aggiungo il membro al gruppo
    this._api.addMembroGruppo(id_gruppo, id_cliente, id_cliente_invitato, id_invito).subscribe({
      error: (err) => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
        this.loading = false;
      },
      complete: () => {
        this.getData();
        this._notification.create('success', 'Sei entrato nel gruppo', "L'invito è stato accettato", { nzPlacement: 'bottomLeft', nzDuration: 5000 });
        this._token.deleteMembro();
        this.loading = false;
      }
    })

  }

  onRefuse(id_invito: number) {
    this.loading = true;

    // Elimino l'invito
    this._api.deleteInvito(id_invito).subscribe({
      error: (err) => {
        this._notification.create('error', 'Errore', err, { nzPlacement: 'bottomLeft', nzDuration: 5000 });
        this.loading = false;
      },
      complete: () => {
        this.getData();
        this._token.deleteMembro();
        this.loading = false;
        this._notification.create('error', 'Invito rifiutato', "L'invito è stato rifiutato! ", { nzPlacement: 'bottomLeft', nzDuration: 5000 });
      }
    })

  }

}