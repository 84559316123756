<div class="container border p-4">
    <div class="row px-3">
        <div class="col-10">
            <h2 class="mb-3">Inviti</h2>
            <p>Ecco i gruppi ai quali sei stato invitato ad unirti.<br />Se decidi di rifiutare un invito, un'email di notifica verrà inviata al capogruppo!</p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p *ngIf="inviti.length < 1" class="text-center my-5 text-muted">Non c'è nessun invito ...</p>
            <nz-table nzBordered class="px-3" #invitiTable *ngIf="inviti.length > 0" [nzData]="inviti" nzShowPagination [nzTotal]="(inviti).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger>
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoInvitante">Invitato da</th>
                        <th [nzSortFn]="onOrdinamentoGruppo" class="text-center">Gruppo</th>
                        <th [nzSortFn]="onOrdinamentoData" class="text-center">Ricevuto il</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let invito of invitiTable.data">
                        <td>{{invito.email_invitante}}</td>
                        <td class="text-center">{{invito.gruppo}}</td>
                        <td class="text-center">{{invito.data_creazione | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td class="text-center">
                            <div class="row">
                                <div class="col-md-6"><button nz-button nzType="primary" class="me-2 w-100" (click)="onAccept(invito.id, invito.id_cliente, invito.id_cliente_invitato, invito.id_gruppo)" [nzLoading]="loading">Accetta</button></div>
                                <div class="col-md-6"><button nz-button nzType="default" class="w-100" nz-popconfirm [nzPopconfirmTitle]="'Sei sicuro di voler rifiutare l`invito? Per poter partecipare al gruppo: ' + invito.gruppo + ' di nuovo dovrai farti rimandare l`invito'" (nzOnConfirm)="onRefuse(invito.id)" [nzLoading]="loading">Rifiuta</button></div>
                            </div>                        

                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>
</div>