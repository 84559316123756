import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthUser } from '../shared/interface.model';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  TOKEN_KEY = 'auth-token';
  USER_KEY = 'auth-user';
  MEMBRO_KEY = 'auth-membro';

  user!: AuthUser | undefined;
  subjectUser = new BehaviorSubject(this.user);

  constructor() {    }

  public getToken(): string {    
    return sessionStorage.getItem(this.TOKEN_KEY) || '';
  }

  public getUser(): AuthUser {
    let user = sessionStorage.getItem(this.USER_KEY);           
    return user ? JSON.parse(user) : '';
  }

  public getMembro(): string {    
    return sessionStorage.getItem(this.MEMBRO_KEY) || '';
  }

  setToken(token: string): void {
    sessionStorage.removeItem(this.TOKEN_KEY);
    sessionStorage.setItem(this.TOKEN_KEY, token);
  }  

  setUser(utente: {id: number, nome: string, cognome: string, email: string, eta: number}): void {
    sessionStorage.removeItem(this.USER_KEY);
    sessionStorage.setItem(this.USER_KEY, JSON.stringify(utente));    

    this.user = this.getUser();    
    this.subjectUser.next({ ...this.user });    
  }  

  setMembro(token: string): void {
    sessionStorage.removeItem(this.MEMBRO_KEY);
    sessionStorage.setItem(this.MEMBRO_KEY, token);
  }  

  deleteToken() {
    sessionStorage.removeItem(this.TOKEN_KEY);
  }

  deleteUser() {
    sessionStorage.removeItem(this.USER_KEY);
  }

  deleteMembro() {
    sessionStorage.removeItem(this.MEMBRO_KEY);
  }

  clearStorage(): void {
    
    sessionStorage.removeItem(this.TOKEN_KEY);
    sessionStorage.removeItem(this.USER_KEY);

    this.user = this.getUser();    
    this.subjectUser.next(undefined);
  }
}
