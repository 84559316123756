import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Carrello, CarrelloGruppo, CarrelloMembro, CarrelloNonPagati, CodiceSconto, Ordine, Prodotto } from '../shared/interface.model';
import * as bootstrap from 'bootstrap';
@Injectable({
  providedIn: 'root',
})
export class CartService {

  constructor(private _notification: NzNotificationService) {
    let carrello = sessionStorage.getItem('carrello');
    let carrelloNonPagati = sessionStorage.getItem('carrello_non_pagati');

    this.carrello = carrello ? JSON.parse(carrello) : { prodotti: [], totale: 0 }
    this.carrelloNonPagati = carrelloNonPagati ? JSON.parse(carrelloNonPagati) : null

    this.subjectCarrello.next(this.carrello);
    this.subjectCarrelloGruppo.next(this.carrelloGruppo);
    this.subjectCarrelloNonPagati.next(this.carrelloNonPagati)
  }

  carrello!: Carrello;
  carrelloGruppo: CarrelloGruppo = { gruppo: 0, carrello: [] };
  carrelloNonPagati!: CarrelloNonPagati;

  codiciSconto: CodiceSconto[] = [];  

  gratis: boolean = false;

  subjectCarrello = new BehaviorSubject(this.carrello);
  subjectCarrelloGruppo = new BehaviorSubject(this.carrelloGruppo);
  subjectCarrelloNonPagati = new BehaviorSubject(this.carrelloNonPagati);

  subjectCodiciSconto = new BehaviorSubject(this.codiciSconto);  

  addProduct(prodotto: Prodotto): void {

    if (!this.isProductInCart(prodotto.id)) {
      this.carrello.prodotti.push(prodotto);
    } else {
      this._notification.create('error', 'Prodotto già nel carrello', `Il prodotto selezionato è gia nel carrello`, { nzPlacement: 'bottomLeft', nzDuration: 1000 });
      return;
    }

    this.carrello.totale = this.getCartTotal();
    this._notification.create('success', 'Prodotto aggiunto al carrello', `${prodotto.nome} è stato aggiunto con successo al carrello`, { nzPlacement: 'bottomLeft', nzDuration: 1000 });
    this.subjectCarrello.next({ ...this.carrello });
    sessionStorage.setItem('carrello', JSON.stringify(this.carrello));
  }

  removeProduct(id: number): void {

    if (this.isProductObbligatorio(id) && !this.isProductDisabilitato(id)) {      
      this.carrello.prodotti = this.carrello.prodotti.filter((prod: Prodotto) => prod.id !== id && prod.obbligatorio).length > 0 ? this.carrello.prodotti.filter((prod: Prodotto) => prod.id !== id) : [];      
      this.carrello.totale = this.getCartTotal();
      this.subjectCarrello.next({ ...this.carrello });
      sessionStorage.setItem('carrello', JSON.stringify(this.carrello));

      if (this.carrello.prodotti.length > 0)
        this._notification.create('info', 'Prodotto rimosso dal carrello', 'Il prodotto selezionato è stato rimosso con successo dal carrello', { nzPlacement: 'bottomLeft', nzDuration: 1000 });
      else 
        this._notification.create('warning', 'Prodotti rimossi dal carrello', `È stato rimosso il tesseramento dello SciClub dal carrello, tutti gli altri prodotti sono stati rimossi!`, { nzPlacement: 'bottomLeft', nzDuration: 3000 });      
    } else {

      let updatedProducts = this.carrello.prodotti.filter((prod: Prodotto) => prod.id !== id);
      this.carrello.prodotti = updatedProducts;
      this.carrello.totale = this.getCartTotal();
      this.subjectCarrello.next({ ...this.carrello });
      sessionStorage.setItem('carrello', JSON.stringify(this.carrello));

      this._notification.create('info', 'Prodotto rimosso dal carrello', 'Il prodotto selezionato è stato rimosso con successo dal carrello', { nzPlacement: 'bottomLeft', nzDuration: 1000 });
    }

  }

  removeOrdine(id: number): void {

    let carrelloNonPagati = sessionStorage.getItem('carrello_non_pagati');
    this.carrelloNonPagati = carrelloNonPagati ? JSON.parse(carrelloNonPagati) : null

    let updatedOrdini = this.carrelloNonPagati.ordini.filter((ordine: Ordine) => ordine.id !== id);
    this.carrelloNonPagati.ordini = updatedOrdini;
    this.carrelloNonPagati.totale = this.getCartNonPagatiTotal();
    this.subjectCarrelloNonPagati.next({ ...this.carrelloNonPagati });
    sessionStorage.setItem('carrello_non_pagati', JSON.stringify(this.carrelloNonPagati));
  }

  addCodiceSconto(codice_sconto: CodiceSconto): void {

    this.carrello.sub_totale = this.getCartSubTotal();
    this.gratis = this.isCartFree(codice_sconto);
    
    codice_sconto.valore = this.isCartFree(codice_sconto) ? this.carrello.sub_totale : codice_sconto.valore;
    
    if (this.gratis && this.carrello.sub_totale <= 0) {
      this._notification.create('warning', 'Totale uguale a zero!', 'Il codice sconto non può essere applicato perchè il totale è già uguale a zero!', { nzPlacement: 'bottomLeft', nzDuration: 7500 });
      return;
    }
    else if (!this.isCodiceScontoInCart(codice_sconto.id)) {
      this.codiciSconto.push(codice_sconto);
    } else {
      this._notification.create('warning', 'Codice Sconto già applicato!', `Il codice sconto inserito è gia stato applicato al carrello`, { nzPlacement: 'bottomLeft', nzDuration: 7500 });
      return;
    }
    
    this.carrello.sub_totale = this.getCartSubTotal();    
    this._notification.create('success', 'Codice sconto applicato al carrello', `${codice_sconto.codice} è stato applicato con successo al carrello`, { nzPlacement: 'bottomLeft', nzDuration: 3000 });
    this.subjectCodiciSconto.next(this.codiciSconto);    

  }

  removeCodiceSconto(id: number): void {

    let updatedCodiciSconto = this.codiciSconto.filter((cod: CodiceSconto) => cod.id !== id);
    this.codiciSconto= updatedCodiciSconto;    
    
    this.carrello.sub_totale = this.getCartSubTotal();
    this.gratis = this.carrello.sub_totale <= 0;
    this.subjectCodiciSconto.next(this.codiciSconto);    

    this._notification.create('info', 'Codice Sconto rimosso dal carrello', 'Il codice sconto selezionato è stato rimosso con successo dal carrello', { nzPlacement: 'bottomLeft', nzDuration: 1000 });

  }

  clearCart(): void {
    this.carrello = { prodotti: [], totale: 0, sub_totale: 0, commissioni: 0 };
    this.subjectCarrello.next({ ...this.carrello });
    sessionStorage.setItem('carrello', JSON.stringify(this.carrello));
  }

  clearCartGruppo(): void {
    this.carrelloGruppo = { gruppo: 0, carrello: [] };
    this.subjectCarrelloGruppo.next(this.carrelloGruppo);
  }

  clearCartNonPagati(): void {
    this.carrelloNonPagati = { ordini: [], totale: 0 };
    this.subjectCarrelloNonPagati.next(this.carrelloNonPagati);
    sessionStorage.setItem('carrello_non_pagati', JSON.stringify(this.carrelloNonPagati));
  }

  getCartTotal(): number {
    let totalSum = 0;

    this.carrello.prodotti.forEach((prod: Prodotto) => (totalSum += (prod.prezzo + (prod.supplemento || 0))));

    // totalSum += this.carrello.commissioni;

    return totalSum;
  }

  getCartSubTotal(): number {

    let subTotalSum = this.getCartTotal();

    this.codiciSconto.forEach((codice: CodiceSconto) => (subTotalSum -= (codice.valore)));

    return subTotalSum;
  }  

  getCartNonPagatiTotal(): number {
    let totalSum = 0;
    this.carrelloNonPagati.ordini.forEach((ordine: Ordine) => (totalSum += ordine.prezzo));

    return totalSum;
  }

  getCartGruppoTotal(): number {    
    return this.carrelloGruppo.carrello.reduce((somma_membro, cart) => { return somma_membro + cart.prodotti.reduce((somma, prod) => somma + (prod.prezzo + (prod.supplemento || 0)), 0); }, 0);    
  }

  getCartLength(): number {
    return this.carrello.prodotti.length;
  }

  isProductInCart(id: number): boolean {
    return this.carrello.prodotti.findIndex((prod: Prodotto) => prod.id === id) !== -1;
  }

  isProductObbligatorio(id: number): boolean {
    return this.carrello.prodotti.findIndex((prod: Prodotto) => prod.id === id && prod.obbligatorio) !== -1;
  }

  isProductSkiCardVialattea(id: number): boolean {
    return this.carrello.prodotti.findIndex((prod: Prodotto) => prod.id === id && prod.id == 1) !== -1;
  }

  isProductDisabilitato(id: number): boolean {
    return this.carrello.prodotti.findIndex((prod: Prodotto) => prod.id === id && prod.disabilitato) !== -1;
  }

  isCartObbligatorio() {
    return this.carrello.prodotti.findIndex((prod: Prodotto) => prod.obbligatorio);
  }

  isMembroinCart(id: number): number {
    return this.carrelloGruppo.carrello.findIndex((carrello: CarrelloMembro) => carrello.cliente.id === id);
  }

  isCodiceScontoInCart(id: number): boolean {
    return this.codiciSconto.findIndex((cod: CodiceSconto) => cod.id === id) !== -1;
  }

  isCartFree(codice: CodiceSconto): boolean {
    return this.carrello.sub_totale <= codice.valore;
  }

  openCart() {
    let carrelloEl = document.getElementById('carrello')
    let carrello = new bootstrap.Offcanvas(carrelloEl!)
    carrello.show()
  }
}
