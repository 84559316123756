<ng-template #indicatorTemplate>
    <img src="../../assets/img/riding-skiing.gif" alt="" width="250px">
</ng-template>
<nz-spin [nzSpinning]="caricamento" [nzIndicator]="indicatorTemplate"  class="spinning spinner" >
    <div class="container border p-0">
        <div class="row p-4 text-center">
            <div class="col">
                <h4>{{stato_ordine ? 'Grazie per il tuo acquisto!': (caricamento ? 'Pagamento in corso!' : 'Errore nel pagamento!')}}</h4>                                
            </div>
        </div>
        <div class="row border-top m-0 p-0">
            <div class="p-3 col text-center" style="margin-top: 30px; margin-bottom: 30px;">
                <div class="success" *ngIf="stato_ordine">                    
                    <p class="m-0">Il pagamento è andato a buon fine. <br />Per tornare allo shop premi sul pulsante qua sottostante!</p>
                </div>                

                <div class="error" *ngIf="!stato_ordine && !caricamento">                    
                    <p class="m-0">Il pagamento non è andato a buon fine.<br /> Per tornare allo shop premi sul pulsante qua sottostante oppure se è stato riscontrato un'errore contattacci a <a href="mailto:supporto@sciclubbussolenovalsusa.com">supporto&#64;sciclubbussolenovalsusa.com</a>!</p>
                </div>

                <div class="error" *ngIf="!stato_ordine && caricamento">                    
                    <p class="m-0">Il pagamento è in corso d'opera.<br /> Non chiudere la scheda per completare il pagamento!</p>
                </div>
            </div>
        </div>
        <div class="row p-3 border-top m-0">
            <div class="col d-flex align-items-center justify-content-end">
                <button nz-button nzSize="large" class="d-inline btn rounded-0 border" routerLink="/">Torna allo shop</button>
            </div>
        </div>
    </div>
</nz-spin>

<router-outlet></router-outlet>